import React from "react";
import CheckoutLeftSection from "./CheckoutLeftSection";
import { Grid } from "@mui/material";
import ClientAdditionalInfo from "../ClientAdditionalInfo";
// import { useCountyPrice } from "components/hooks/useCountyPrice";
import ItemsInTheCartSection from "./ItemsInTheCartSection";

const CheckoutDrawerTwo = ({
  client,
  invoiceDetails,
  grossPrice = 0,
  netPrice = 0,
  allAmounts,
  handleAllAmounts,
  setDrawer,
}) => {
  // const { format } = useCountyPrice();
  return (
    <div style={{ position: "relative", height: "90vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} pl={3} pr={3}>
          <ClientAdditionalInfo client={client} showHistory={false} />

          <ItemsInTheCartSection invoiceDetails={invoiceDetails} />
        </Grid>
        <Grid item xs={0.3}>
          <div class="vertical-line"></div>
        </Grid>
        <Grid item xs={5.7}>
          <>
            <CheckoutLeftSection
              client={client}
              invoiceDetails={invoiceDetails}
              grossPrice={grossPrice}
              netPrice={netPrice}
              invoiceDiscount={allAmounts?.invoiceDiscount}
              invoiceTaxAmount={allAmounts?.invoiceTaxAmount}
              handleAllAmounts={handleAllAmounts}
            />
            <div style={{ position: "absolute", bottom: "10px", right: "5px" }}>
              <button
                onClick={() => {
                  setDrawer({
                    open: true,
                    type: "PAYMENT_DRAWER",
                    drawerWidth: "60%",
                    title: "PAYMENT",
                    handleClose: () => {
                      setDrawer({ open: false });
                    },
                  });
                }}
                className="customButton"
              >
                PROCEED TO PAYMENT
              </button>
            </div>
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default CheckoutDrawerTwo;
