export const ImagePath = {
    LANDING: 'landing',
    USERS: 'users',
    ECOMMERCE: 'e-commerce',
    PROFILE: 'profile'
};

// ==============================|| NEW URL - GET IMAGE URL ||============================== //

// export function getImageUrl(name, path) {
//     console.log(new URL(`/src/assets/images/${path}/${name}`, process.env.REACT_APP_PUBLIC_URL).href);
//     return new URL(`/src/assets/images/${path}/${name}`, process.env.REACT_APP_PUBLIC_URL).href;
// }

export function getImageUrl(name, path) {
    const baseUrl = process.env.REACT_APP_PUBLIC_URL
    try {
        return new URL(`/src/assets/images/${path}/${name}`, baseUrl).href;
    } catch (error) {
        console.error('Invalid URL:', error);
        return ''; // Or handle the error appropriately
    }
}
