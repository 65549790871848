import { useCountyPrice } from "components/hooks/useCountyPrice";
import React from "react";

const ItemsInTheCartSection = ({ invoiceDetails =[]}) => {
  const { format } = useCountyPrice();
  return (
    <div className="customer-info-checkout-parent-container customer-items-in-cart">
      <h4>Items in the cart</h4>
      <div className="checkout-item-container">
        {invoiceDetails?.map((item, index) => {
          return (
            <div className="checkout-item" key={index}>
              <label>
                <span className="item-number">{index + 1}.</span>
                {item?.currItem?.title}
                <span style={{ color: "#585858" }}>
                  <span style={{ paddingInline: "3px" }}>x</span>
                  {item.itemQuantity}
                </span>
              </label>
              <span>
                {format(Number(item.itemAmount) * Number(item.itemQuantity))}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ItemsInTheCartSection;
