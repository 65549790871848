import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

// material-ui
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// assets
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { useCountyPrice } from "components/hooks/useCountyPrice";

//assets
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Autocomplete,
  Box,
  Collapse,
  Grid,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { initializeExpandCategory } from "store/catalogue/slice";
import SubCard from "ui-component/cards/SubCard";
import { EnhancedTableToolbar } from "pages/catalogue/EnhancedTableToolbar";
import BpCheckbox from "ui-component/extended/Form/Checkbox";
import { capitalize, validPackageForClient } from "utils/general";
import { v4 as uuid } from "uuid";
import { format as dateFormat} from "date-fns"
// import { useSelector } from "react-redux";

// ==============================|| CREATE INVOICE - ITEMS ||============================== //

const InvoiceCatelogueItemList = ({
  packageRelation
}) => {
  const { format } = useCountyPrice();
  const [open, setOpen] = useState(false);
  // const [selected, setSelected] = React.useState([]);
  // const { customers } = useSelector((state) => state.customer);
  // const { serviceList, productList, packagesList } = useSelector((state) => state.catalogue);
  const handleClickOpen = (value) => {
    if(value === open){
      setOpen('');
    }else{
      setOpen(value);
    }
    initializeExpandCategory();
  };

  const checkPackageReedemStatus = (row) => {
    if(row?.isRedeemed == 1){
      return "Consumed"
    }else{
      const isPackageStartReedem = row?.package?.packageItems?.every((el)=> {return el?.isServiceRedeemed == 0})
      if(isPackageStartReedem){
        return "Not Started";
      }else{
        const isPackagePartialReedem = row?.package?.packageItems?.some((el)=> {return el?.isServiceRedeemed == 1})
        if(isPackagePartialReedem){
             return "Partial Consumed"
        }
      }
    }
  }

  const renderService = useMemo(() => 
    (row) =>
    {
  return (
    <TableRow>
      <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open === row?.id} timeout="auto" unmountOnExit>
          {open === row?.id && (
            <Box sx={{ margin: 1 }}>
              <SubCard
                title=""
                //   content={false}
              >
                <TableContainer>
                  <Table size="small" aria-label="purchases">
                    <TableHead sx={{ background: "#e3f2fd" }}>
                      <TableRow>
                        <TableCell>Service Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>isRedeemed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.package?.packageItems?.map((historyRow) => (
                        <TableRow hover key={uuid()}>
                          <TableCell>
                            {capitalize(historyRow?.itemMetaData?.title)}
                          </TableCell>

                          <TableCell>
                            {format(historyRow?.itemMetaData?.price)}
                          </TableCell>
                          <TableCell>{historyRow?.quantity}</TableCell>
                          <TableCell>
                            {historyRow?.itemMetaData?.duration}
                          </TableCell>
                          <TableCell>
                            {historyRow?.isServiceRedeemed === 1
                              ? "Consumed"
                              : "Not Consumed"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </SubCard>
            </Box>
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  );}
    , [open])
  return (
    <TableContainer sx={{ paddingBottom: 5 }}>
      <Table className="inv-inn-table" sx={{ border: "1px solid #e9e9e9" }}>
        <TableHead sx={{ background: "#e3f2fd" }}>
          <TableRow>
            <TableCell sx={{ pl: 3 }}></TableCell>
            <TableCell sx={{ pl: 3 }}>Package Name</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="left">Invoice Number</TableCell>
            <TableCell align="left">Invoice Date</TableCell>
            <TableCell align="left">Expire Date</TableCell>
            <TableCell align="left">Reedem</TableCell>

            {/* <TableCell align="right">Discount</TableCell>
            <TableCell align="right">Assigned Staff</TableCell>
            <TableCell align="right">Total</TableCell> */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!packageRelation ||
            (packageRelation.length <= 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Now Rows
                </TableCell>
              </TableRow>
            ))}
          {packageRelation?.map((row, index) => (
            <>
              <TableRow key={uuid()}>
                <TableCell sx={{ pl: 3, minWidth: 60, width: 60, height: 46 }}>
                  <Stack direction="row" spacing={0.25} alignItems="center">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleClickOpen(row?.id)}
                    >
                      {open === row?.id ? (
                        <KeyboardArrowDownIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowRightIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Stack>
                </TableCell>
                <TableCell sx={{ pl: 3 }}>
                  <Typography variant="subtitle1">
                    {row?.package?.title}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {format(row?.package?.netPrice)}
                </TableCell>
                <TableCell align="left">
                  {row?.invoice?.invoiceNo ?? "-"}
                </TableCell>
                <TableCell align="left">
                  {dateFormat(new Date(row?.invoice?.invoiceDate),"dd-MM-yyyy")}
                </TableCell>
                <TableCell align="left">
                  {validPackageForClient(
                    row?.invoice?.invoiceDate,
                    row?.dueDay
                  )}
                </TableCell>
                <TableCell align="left">
                  {checkPackageReedemStatus(row)}
                </TableCell>
              </TableRow>
              {renderService(row)}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

InvoiceCatelogueItemList.propTypes = {
  seletcedClientPackageList: PropTypes.array,
};

export default InvoiceCatelogueItemList;