import React from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { patchAPI } from "network/Api";
import { BaseURL } from "components/constants/api-paths.c";
import { useCompanyData } from "components/hooks/useCompanyData";
import { getCustomers } from "store/customer/slice";

const PendingPaymentComponent = ({
  name,
  clientInvoicePaymentMethods,
  pendingPopup,
  setPendingPopup,
}) => {
  const { paymentOptions } = useSelector((state) => state.invoice);
  const companyId = useCompanyData();

  const dispatch = useDispatch();

  const onDoneClick = async () => {
    setPendingPopup({ open: false });
    const payload = {
      id: pendingPopup.id,
      amount: pendingPopup.amount,
      status: pendingPopup.status,
      mode: pendingPopup.mode,
      paymentMethodId: pendingPopup.paymentMethodId,
    };
    const response = await patchAPI(
      `${BaseURL}invoice/payment/update`,
      payload
    );
    if (response?.statusCode === 200) {
      dispatch(getCustomers({ companyId }));
    }
  };
  return (
    <>
      <Dialog
        open={pendingPopup?.open || false}
        onClose={() => setPendingPopup({ open: false })}
        sx={{ "& .MuiDialog-paper": { maxWidth: "100%", width: 600 } }}
      >
        {pendingPopup.type === "pendinglist" && (
          <Grid item xs={12}>
            <DialogContent>
              <DialogContentText mb={2} id="alert-dialog-description">
                <strong style={{ margin: "10px" }}>
                  List of Pending Payments of {name}
                </strong>
              </DialogContentText>
              <TableContainer>
                <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pending Amount</TableCell>
                      <TableCell>Settle Payment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientInvoicePaymentMethods
                      ?.filter((i) => i?.paymentMethod?.title === "Pending")
                      ?.map((i) => {
                        return (
                          <TableRow>
                            <TableCell>{i?.amount}</TableCell>
                            <TableCell>
                              <Button
                                onClick={() => {
                                  setPendingPopup((prev) => {
                                    return {
                                      ...prev,
                                      type: "paymentmode",
                                      amount: i.amount,
                                      id: i.id,
                                    };
                                  });
                                }}
                              >
                                Pay
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
          </Grid>
        )}
        {pendingPopup.type === "paymentmode" && (
          <DialogContent>
            <DialogContentText mb={2} id="alert-dialog-description">
              <strong>List of Pending Payments of {name}</strong>
            </DialogContentText>
            <DialogContentText mb={2} id="alert-dialog-description">
              <Typography variant="body2" component="span">
                Select Payment Method
              </Typography>
            </DialogContentText>

            <Autocomplete
              allowClearable={false}
              className="staff-autocomplete"
              options={paymentOptions?.filter((i) => i?.title !== "Pending")}
              getOptionLabel={(option) => {
                if (!option) return "";
                return option?.title;
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Please Select payment" />
              )}
              onChange={(e, v, r) => {
                setPendingPopup((prev) => {
                  return {
                    ...prev,
                    paymentMethodId: v.id,
                    mode: v.title,
                    status: "completed",
                  };
                });
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                height: "10vh",
              }}
            >
              <strong>Collected Amount : {pendingPopup.amount}</strong>
              <div style={{ display: "flex", gap: "5px" }}>
                <Button
                  style={{
                    background: "#E0E0E0",
                    color: "#000",
                    border: "none",
                  }}
                  variant="outlined"
                  onClick={() =>
                    setPendingPopup((prev) => {
                      return {
                        ...prev,
                        type: "pendinglist",
                        paymentMethodId: "",
                      };
                    })
                  }
                >
                  Back
                </Button>
                <Button
                  disabled={!pendingPopup?.paymentMethodId}
                  variant="contained"
                  onClick={onDoneClick}
                >
                  Done
                </Button>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default PendingPaymentComponent;
