import React, { useState } from "react";
// import PersonIcon from "@mui/icons-material/Person";
// import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
// import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import { Divider, Grid, InputLabel } from "@mui/material";
import InvoiceDiscount from "./InvoiceDiscount";
import InvoiceTaxField from "./InvoiceTaxField";
import OrderSummaryView from "./OrderSummaryView";

const CheckoutLeftSection = ({
  client,
  invoiceDetails,
  grossPrice = 0,
  invoiceDiscount = 0,
  invoiceTaxAmount = 0,
  netPrice = 0,
  handleAllAmounts,
}) => {
  const { format } = useCountyPrice();

  const [taxValue, setTaxValue] = useState(null);

  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <InvoiceDiscount
              grossPrice={grossPrice}
              handleAllAmounts={handleAllAmounts}
              setTaxValue={setTaxValue}
            />
          </Grid>
          <Grid item sm={12}>
            <InvoiceTaxField
              taxableAmount={grossPrice - invoiceDiscount}
              handleAllAmounts={handleAllAmounts}
              taxValue={taxValue}
              setTaxValue={setTaxValue}
            />
          </Grid>
        </Grid>
        <div
          style={{
            height: "3rem",
            paddingTop: "2rem",
          }}
        >
          <Divider />
        </div>
        <OrderSummaryView
          {...{ grossPrice, invoiceDiscount, invoiceTaxAmount, netPrice }}
        />
      </>

      {/* ORDER OVERVIEW */}
      {/* <div className="customer-info-checkout-parent-container customer-items-in-cart">
        <h4>Items in the cart</h4>
        <div className="checkout-item-container">
          {invoiceDetails?.map((item, index) => {
            return (
              <div className="checkout-item" key={index}>
                <label>
                  <span className="item-number">{index + 1}.</span>
                  {item?.currItem?.title}
                </label>
                <span>{format(item.itemAmount)}</span>
              </div>
            );
          })}
        </div>
      </div> */}

      {/* Customer Section */}
      {/* <div className="customer-info-checkout-parent-container">
        <div className="customer-items-in-cart">
          <h4>Customer Information</h4>
        </div>
        <div>
          <div className="customer-info-checkout-container">
            <span>
              <PersonIcon />
            </span>
            <span>{client?.firstName || "N/A"} </span>
          </div>
          <div className="customer-info-checkout-container">
            <span>
              <AlternateEmailIcon />
            </span>
            <span>{client?.email || "N/A"}</span>
          </div>
          <div className="customer-info-checkout-container">
            <span>
              <PhoneInTalkIcon />
            </span>
            <span>{client?.phoneNumber || "N/A"}</span>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CheckoutLeftSection;
