import React from "react";
import PropTypes from "prop-types";

// material-ui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// material-ui
// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
// import { useAppSelector as useSelector } from "store";
// assets
import { TableHead
  // , alpha, useTheme
 } from "@mui/material";
import { v4 as uuid } from "uuid";
// import { useCountyPrice } from "components/hooks/useCountyPrice";
import dayjs from "dayjs";

const MembershipHistoryTable = ({ membershipPlansRltn }) => {
  // const theme = useTheme();
  // const { invoices } = useSelector((state) => state.invoice);
  // const { format } = useCountyPrice();


  // const statusColor = {
  //   pending:{
  //     label:'Pending',
  //     color : 'warning.dark',
  //     chipcolor :'warning.light',
  //   },
  //   completed:{
  //     label:'Paid',
  //     color : 'success.dark',
  //     chipcolor : alpha(theme.palette.success.light, 0.6),
  //   },
  //   cancelled:{
  //     label:'Cancelled',
  //     color : 'orange.dark',
  //     chipcolor : alpha(theme.palette.error.light, 0.6),
  //   }
  // }
  return (
    <MainCard content={false} sx={{borderRadius:"0px !important"}}>
      {/* table */}
      {/* {loading && ( <Loader /> )} */}
      <TableContainer>
        <Table
          sx={{ minWidth: 750, border:"1px solid #e9e9e9" }}
          aria-labelledby="tableTitle"
          className="invoice-main-table"
        >
          <TableHead sx={{background:"#e3f2fd"}}>
            <TableRow>
              <TableCell>Created Date</TableCell>
              <TableCell>Membership Name</TableCell>
              {/* <TableCell>Membership Type</TableCell> */}
              <TableCell>Wallet Value</TableCell>
              {/* <TableCell>Percentage</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {membershipPlansRltn?.map((row, index) => {
              return ( <TableRow
                key={uuid()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell> {dayjs(row?.membershipPlan?.createdAt).format('DD-MM-YYYY')}</TableCell>
                <TableCell> {row?.membershipPlan?.title}</TableCell>
                {/* <TableCell> {row?.membershipPlan?.type}</TableCell> */}
                <TableCell> {row?.membershipPlan?.price}</TableCell>
                {/* <TableCell> {row?.membershipPlan?.percentage}</TableCell> */}
              </TableRow>)
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

MembershipHistoryTable.propTypes = {
  clientDetail: PropTypes.object,
};

export default MembershipHistoryTable;
