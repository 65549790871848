// third-party
import { createSlice , current } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { ENDPOINTS } from 'network/ENDPOINT';
import { customerRegister } from 'utils/types/requestType';
import { Users } from 'utils/types/responseType';
import { NavigateFunction } from 'react-router-dom';
import { openSnackbar } from 'store/snackbar/slice';
import { getCustomersDetails } from 'store/customer/slice';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    token: [],
    isFetching:false
};

const slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {

         // HAS ERROR
         hasError(state, action) {
            state.error = action.payload;
        },
     
        // GET REPORT TOKEN SUCCESS
        getReportAccessTokenSuccess(state, action) {
            state.token = action.payload.token;
            state.isFetching=false
        },
        //SET ISFETCHING
        setIsFetching(state, action) {
            state.isFetching = action.payload
        }

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReportAccessToken({companyId , reportId }:{companyId:string, reportId:string }) {
    return async () => {
        try {
            console.log("Debug")
            dispatch(slice.actions.setIsFetching(true))
            let response = await axios.get(`${ENDPOINTS.REPORT_GET_ACCESSTOKEN}?companyId=${companyId}&reportId=${reportId}`);

            if(response?.data?.success){
                dispatch(slice.actions.getReportAccessTokenSuccess(response.data.data));
            }else{
            }
            dispatch(slice.actions.setIsFetching(false));
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false))
        }
    };
}
