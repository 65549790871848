import React from "react";
import PropTypes from "prop-types";

// material-ui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// material-ui
// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
// import { useAppSelector as useSelector } from "store";
// assets
import { Chip, TableHead
  // , alpha, useTheme
 } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import dayjs from "dayjs";

const WalletTransactionTable = ({ wallets }) => {
  // const theme = useTheme();
  // const { invoices } = useSelector((state) => state.invoice);
  const { format } = useCountyPrice();

  // const statusColor = {
  //   pending: {
  //     label: "Pending",
  //     color: "warning.dark",
  //     chipcolor: "warning.light",
  //   },
  //   completed: {
  //     label: "Paid",
  //     color: "success.dark",
  //     chipcolor: alpha(theme.palette.success.light, 0.6),
  //   },
  //   cancelled: {
  //     label: "Cancelled",
  //     color: "orange.dark",
  //     chipcolor: alpha(theme.palette.error.light, 0.6),
  //   },
  // };
  return (
    <MainCard content={false} sx={{ borderRadius: "0px !important" }}>
      {/* table */}
      {/* {loading && ( <Loader /> )} */}
      <TableContainer>
        <Table
          sx={{ minWidth: 750, border: "1px solid #e9e9e9" }}
          aria-labelledby="tableTitle"
          className="invoice-main-table"
        >
          <TableHead sx={{ background: "#e3f2fd" }}>
            <TableRow>
              <TableCell>Date </TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Wallet Amount</TableCell>
              <TableCell>Transaction </TableCell>
              <TableCell>Type of Transaction</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wallets?.walletTransactions?.map((row, index) => {
              return (
                <TableRow
                  key={uuid()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {" "}
                    {dayjs(row?.createdAt).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell> {row?.invoiceNo ?? "-"}</TableCell>
                  <TableCell>{format(row?.balanceBefore)}</TableCell>
                  <TableCell>
                    <Chip
                      label={format(row?.amount)}
                      size="small"
                      color={
                        row?.transactionType === "deposit" ? "success" : "error"
                      }
                      variant="filled"
                      sx={{ ml: 0.75, height: "20px" }}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    {row?.transactionType === "deposit"
                      ? index ==
                        wallets?.walletTransactions?.length -
                          1
                        ? "Membership Created"
                        : "Add Topup"
                      : "Used"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

WalletTransactionTable.propTypes = {
  clientDetail: PropTypes.object,
};

export default WalletTransactionTable;
