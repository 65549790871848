// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import store, { dispatch } from '../index';
import { openSnackbar } from 'store/snackbar/slice';
import { ENDPOINTS } from 'network/ENDPOINT';
import { getAllWeekdays, userName } from 'utils/general';
import { selectServiceList } from 'store/catalogue/slice';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid'
import {
    formatDate
} from 'modules/@mobiscroll/react';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    schedules: {},
    isFetching:false, //for initial fetching
    isLoading:false, //for upsert/delete
    empBusinessTimings:{}
};

const ScheduleStatus  = {
    LEAVE : "leave",
    PRESENT : "present",
    ABSENT : "absent",
    leave:false,
    present:true

}
const daysOfWeek = getAllWeekdays();
const getCurrentWeekDates = () =>{
    const startOfWeek = dayjs().startOf('Week'); // Monday
    const dates = [];
  
    for (let i = 0; i < 7; i++) {
      dates.push(startOfWeek.add(i, 'day').format('YYYY-MM-DD'));
    }
  
    return dates;
  }

  function generateDateRange(start , end) {
    console.log("start" , start , "end " ,end)
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dateArray = [];
  
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(currentDate.toISOString().split('T')[0]); // Format YYYY-MM-DD
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
  
    return dateArray;
  }
  
 

const slice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },



        //GET SCHEDULES SUCCESS

        // GET USER
        getSchedulesSuccess(state, action) {
            console.log("action.payload" , action.payload)
            const schedules = new Map();
            const empBusinessTimings = new Map();
            const scheduleList = action.payload.employee;
            const staff = action.payload.staffs;
            const scheduleDateRange = Array.isArray(action.payload.scheduleDateRange) 
                                        ? action.payload.scheduleDateRange[0].split(",")
                                        : action.payload.scheduleDateRange.split(",")
            const dateRange = generateDateRange(scheduleDateRange[0] ,scheduleDateRange[1]);
            const businessHours = action.payload.businessHours
            const employees = staff.map(s => {
                const schedule = scheduleList
                  .filter(emp => s.id === emp.employeeId)
                  .reduce((acc, empSchedule) => {
                    // Add the found schedules to an object indexed by date
                    acc[empSchedule.date] = empSchedule;
                    return acc;
                  }, {});
                 
                // Ensure all dates from the range are present
                console.log("dateRange" , dateRange)
                const fullSchedule = dateRange.map(date => {
                   
                    const week_day_name =daysOfWeek[dayjs(date).day()];
                   
                    const businessScheduleHour = businessHours?.find(b => b.prefDay==week_day_name.toLowerCase() && b.isdayoff===false) || false
                    let startDateTimeString , endDateTimeString;
                    if(businessScheduleHour){
                        startDateTimeString = `${date}T${businessScheduleHour.startTime}`;
                        endDateTimeString = `${date}T${businessScheduleHour.endTime}`;
                    }
                    //console.log("schedule[date]" , schedule[date] , date)
                    if(schedule[date]){
                        startDateTimeString = `${date}T${schedule[date].from}`;
                        endDateTimeString = `${date}T${schedule[date].to}`;
                        return { date, 
                            id:schedule[date].id,
                            resource: s.id, 
                            start:startDateTimeString , 
                            end: endDateTimeString ,
                            status: schedule[date]['status'],
                            title:  schedule[date]['status']==='present' 
                                        ? `${formatDate ('HH:mm', new Date(startDateTimeString))} - ${formatDate('HH:mm', new Date(endDateTimeString))}`
                                        :'On Leave',
                            isPresent: schedule[date]['status']==='present' ?  true :false,
                            disableDay: false
                            
                        }
                    }else{
                        if(businessScheduleHour){
                            return { date, 
                                resource: s.id, 
                                start:businessScheduleHour?startDateTimeString :dayjs(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss') , 
                                end:businessScheduleHour?endDateTimeString : dayjs(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss') ,
                                status: businessScheduleHour ? "present" :"absent",
                                title:  businessScheduleHour ? `${formatDate ('HH:mm', new Date(startDateTimeString))} - ${formatDate('HH:mm', new Date(endDateTimeString))}` : "Closed",
                                isPresent: businessScheduleHour ?  true :false,
                                disableDay: businessScheduleHour?false:true
                                
                            }
                        }else{
                            return {}
                        }
                    }
                });
                

                return {
                  employee: s,
                  schedule: fullSchedule,
                  businessHours:fullSchedule.map(shift=>{
                    if(shift.date)
                        return { daysOfWeek: [new Date(shift.date).getDay()], startTime: shift.start, endTime: shift.end }
                    else
                        return null;
                }).filter(bh=>bh!==null)
                };
              });
              
              employees.forEach(e=>{
                console.log("e.employee.employeeId" , e)
                const s= e.schedule;
                //const empbusinessHours = e.businessHours
                const emplId = e.employee.id;
                // const businessHours = s.map(shift=>{
                //     return { daysOfWeek: [new Date(shift.date).getDay()], startTime: shift.start, endTime: shift.end }
                // })
                // s.businessHours = businessHours.filter(bh=>bh.startTime!==undefined)
                // console.log("s.businessHour" , s.businessHours)
                if (!empBusinessTimings.has(emplId)) {

                    empBusinessTimings.set(emplId, e.businessHours);
                }else{
                    empBusinessTimings.set(emplId, [...empBusinessTimings.get(emplId),e.businessHours])
                }
                if (!schedules.has(emplId)) {

                    schedules.set(emplId, s);
                }else{
                        schedules.set(emplId, [...schedules.get(emplId),s])
                }
              })
              
              
            // scheduleList.forEach(s => {
            //     const startDateTimeString = `${s.date}T${s.from}`;
            //     const endDateTimeString = `${s.date}T${s.to}`;
  
            //         const shift = {
            //             ...(!ScheduleStatus[s.status] && {color:'#d31b1b'}),
            //             id:s.id,
            //             start: `${startDateTimeString}`,//'2024-09-23T07:00',
            //             end: `${endDateTimeString}`,
            //             title: ScheduleStatus[s.status] ? `${formatDate ('HH:mm', new Date(startDateTimeString))} - ${formatDate('HH:mm', new Date(endDateTimeString))}` : 'ABSENT',
            //             resource: s.employeeId,
            //             slot: dayjs(endDateTimeString).get("hour") > 13 ? 2 : 1 ,
            //             isPresent: ScheduleStatus[s.status]
            //         }
            //     if (!schedules.has(s.employeeId)) {

            //         schedules.set(s.employeeId, [shift]);
            //     }else{
            //             schedules.set(s.employeeId, [...schedules.get(s.employeeId),shift])
            //     }
            // })
           
            state.schedules = Object.fromEntries(schedules);
            state.empBusinessTimings = Object.fromEntries(empBusinessTimings);

        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        deleteScheduleSuccess(state, action){
                const event = action.payload;
                const newScheduleMap = new Map(Object.entries(state.schedules));
                //const result = Array.from(scheduleObj.values()).flatMap(arr => arr);
               
                if(newScheduleMap.has(event.resource)){
                    const newSchedules = newScheduleMap.get(event.resource);
                    newScheduleMap.set(event.resource , newSchedules.filter(ns=>ns.id!=event.id));
                    state.schedules =Object.fromEntries(newScheduleMap); 
                }
                
        },
        addScheduleSuccess(state, action){
            const event = action.payload;
            //event.id = uuid();
          //  console.log("event" , event);
            const newScheduleMap = new Map(Object.entries(state.schedules));
            //const result = Array.from(scheduleObj.values()).flatMap(arr => arr);
           
            if(newScheduleMap.has(event.resource)){
                const existingSchedules = newScheduleMap.get(event.resource) || [];
                newScheduleMap.set(event.resource , [...existingSchedules, event]);
                //newScheduleMap.set(event.resource , newSchedules.push(event));
                state.schedules =Object.fromEntries(newScheduleMap); 
            }
            
        },
        updateScheduleSuccess(state, action){
            const event = action.payload;
            console.log("event" , event);
            //event.id = uuid();
          //  console.log("event" , event);
            const newScheduleMap = new Map(Object.entries(state.schedules));
            //const result = Array.from(scheduleObj.values()).flatMap(arr => arr);
           
            if(newScheduleMap.has(event.resource)){
                const existingSchedules = newScheduleMap.get(event.resource) || [];
                console.log("existingSchedules" , existingSchedules)
                const removeUpdatedSchedule = existingSchedules.filter(ns=>ns.id!=event.id);
                newScheduleMap.set(event.resource , [...removeUpdatedSchedule, event]);
                //newScheduleMap.set(event.resource , newSchedules.push(event));
                state.schedules =Object.fromEntries(newScheduleMap); 
            }
            
        }

    }
});


export function getSchedules({ companyId, date, scheduleDateRange, employeeId }) {
    return async () => {
        try {
           
            dispatch(slice.actions.setIsLoading(true))
            if(!scheduleDateRange){
                const weekDays = getCurrentWeekDates();
                scheduleDateRange = [`${weekDays[0]},${weekDays[weekDays.length-1]}`];
            }
            
            const response = await axios.get(`${ENDPOINTS.APPOINTMENT_TIME_BLOCK}?companyId=${companyId}${
                date
                  ? "&date=" + date
                  : scheduleDateRange
                  ? "&scheduleDateRange=" + scheduleDateRange
                  : ""
              }${employeeId ? "&employeeId=" + employeeId : ""}`);
              
            dispatch(slice.actions.getSchedulesSuccess({...response.data.data,scheduleDateRange , staffs:store.getState().employees?.employeeList ,businessHours:store.getState().stores?.store?.schedules}));
            dispatch(slice.actions.setIsLoading(false))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setIsLoading(false))
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}



export function deleteSchedule(event) {
    return async () => {
        try {
            dispatch(slice.actions.setIsLoading(true));
            const response = await axios.delete(`${ENDPOINTS.DELETE_EMPLOYEE_SHIFT}/${event.id}`);
            dispatch(slice.actions.deleteScheduleSuccess(event));
            dispatch(slice.actions.setIsLoading(false));
        } catch (error) {
            dispatch(slice.actions.setIsLoading(false))
            dispatch(slice.actions.hasError(true))
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}


export function addSchedule(event) {
    return async () => {
        try {
            //const {body , companyId} = payload
            dispatch(slice.actions.setIsLoading(true));
            const closeHour = dayjs(event.start).hour();
            const body = {
                employeeId: event?.resource,
                startDate: dayjs(event.start).format("YYYY-MM-DD"),
                endDate: dayjs(event.end).format("YYYY-MM-DD"),
                shiftData: [
                    {
                        targetDays: [dayjs(event.start).format('dddd')],
                        shiftNTimeData: [
                            {
                                "shiftId":closeHour > 13 ? 2 :1,
                                "from": formatDate('HH:mm', event.start),
                                "to": formatDate('HH:mm', event.end),
                                "status": event.isPresent ? ScheduleStatus.PRESENT : ScheduleStatus.LEAVE
                            }
                        ],
                    },
                ],
            };
            const response = await axios.post(`${ENDPOINTS.ADD_EMPLOYEE_SHIFT}` , body);
            await dispatch(slice.actions.updateScheduleSuccess(event));
            dispatch(slice.actions.setIsLoading(false));
        } catch (error) {
            dispatch(slice.actions.setIsLoading(false))
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function updateSchedule(event) {
    return async () => {
        try {
            
            dispatch(slice.actions.setIsLoading(true));
            const closeHour = dayjs(event.start).hour();
            const body = {
                //id:event.id,
                employeeId: event?.resource,
                startDate: dayjs(event.start).format("YYYY-MM-DD"),
                endDate: dayjs(event.end).format("YYYY-MM-DD"),
                shiftData: [
                    {
                        targetDays: [dayjs(event.start).format('dddd')],
                        shiftNTimeData: [
                            {
                                "shiftId":closeHour > 13 ? 2 :1,
                                "from": formatDate('HH:mm', event.start),
                                "to": formatDate('HH:mm', event.end),
                                "status": event.isPresent ? ScheduleStatus.PRESENT : ScheduleStatus.LEAVE
                            }
                        ],
                    },
                ],
            };
            const response = await axios.post(`${ENDPOINTS.ADD_EMPLOYEE_SHIFT}` , body);
            await dispatch(slice.actions.updateScheduleSuccess(event));
            dispatch(slice.actions.setIsLoading(false));
        } catch (error) {
            dispatch(slice.actions.setIsLoading(false))
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}


// Reducer
export default slice.reducer;

export const {getSchedulesSuccess} = slice.actions;

// ----------------------------------------------------------------------

// export function getSchedules(data){
//     return async () => {
//         dispatch(slice.actions.getSchedulesSuccess(data));
//     }
// }
