import { Divider, InputLabel } from "@mui/material";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import React from "react";

const OrderSummaryView = ({
  grossPrice,
  invoiceDiscount,
  invoiceTaxAmount,
  netPrice,
}) => {
  const { format } = useCountyPrice();
  return (
    <>
      <div>
        <h3>ORDER SUMMARY</h3>
        <div className="customer-info-checkout-parent-container customer-items-in-cart">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "100%",
              padding: "5px 10px",
            }}
          >
            <div className="checkout-item">
              <div>
                <InputLabel>Subtotal</InputLabel>
              </div>
              <div>{format(grossPrice || 0)}</div>
            </div>
            <div className="checkout-item" style={{ color: "#E68E8F" }}>
              <div>
                <InputLabel>Discount</InputLabel>
              </div>
              <div>- {format(invoiceDiscount || 0)}</div>
            </div>

            <div className="checkout-item">
              <div>
                <InputLabel>Total</InputLabel>
              </div>
              <div>{format(grossPrice - invoiceDiscount || 0)}</div>
            </div>

            <div style={{ color: "#93B6ED" }} className="checkout-item">
              <div>
                <InputLabel>Tax</InputLabel>
              </div>
              <div>+ {format(invoiceTaxAmount || 0)}</div>
            </div>
            <div style={{ paddingTop: "2px", height: "10px" }}>
              <Divider />
            </div>
            <div style={{ color: "#1A5D37" }} className="checkout-item">
              <div>
                <InputLabel>Payable Amount</InputLabel>
              </div>
              <div>{format(netPrice || 0)}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSummaryView;
