import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('pages/authentication/authentication1/Login1')));
const AuthCodeVerification1 = Loadable(lazy(() => import('pages/authentication/authentication1/CodeVerification1')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/authentication/authentication1/ForgotPassword1')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/authentication1/Register1')));
const AuthResetPassword = Loadable(lazy(() => import('pages/authentication/authentication3/ResetPassword3')));
const AuthCheckMail = Loadable(lazy(() => import('pages/authentication/authentication3/CheckMail3')));
const Register = Loadable(lazy(() => import("pages/register")));
const TermsandCondition = Loadable(lazy(()=>import('pages/terms-and-conditions/TermsAndConditions')));
const AppointmentPage = Loadable(lazy(() => import('pages/client-view/AppointmentView')));
const InvoicePage = Loadable(lazy(() => import('pages/client-view/index')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
           //element: <Login />
        },
        {
            path: '/login',
            element: <AuthLogin />
           //element: <Login />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/terms-conditions',
            element: <TermsandCondition />
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset-password',
            element: <AuthResetPassword />
        },
        {
            path: '/check-mail',
            element: <AuthCheckMail />
        },
        {
            path: '/register-legacy',
            element: <Register />
        },
        {
            path: '/forgot-password/validate/otp',
            element: <AuthCodeVerification1 />
        },
        {
            path: '/view-invoice',
            element: <InvoicePage />
        },
        {
            path: '/view-appointment',
            element: <AppointmentPage />
        }


    ]
};

export default LoginRoutes;
