import React, { useState } from "react";
import Service from "../../../../assets/images/icons/services.png";
import Package from "../../../../assets/images/icons/package.png";
import Membership from "../../../../assets/images/icons/membership.png";
import Products from "../../../../assets/images/icons/products.png";
import redeem from "../../../../assets/images/icons/package-redeem.png";
import { Tooltip } from "@mui/material";
import DynamicErrorPopup from "./DynamicErrorPopup";

const DRAWER_ARRAY = (client, packageRedeemOptions) => [
  {
    open: true,
    drawerWidth: "50%",
    label: "Redeem",
    title: "PACKAGE REDEEM",
    type: "PACKAGE_REDEEM_INVOICE",
    id: 0,
    color: "gold",
    show: packageRedeemOptions?.length > 0,
  },
  {
    open: true,
    drawerWidth: "50%",
     label: "Service",
    title: "SERVICE",
    type: "SERVICE_INVOICE",
    id: 1,
    color: "#33AC4F",
    
    show: true,
  },
  {
    open: true,
    drawerWidth: "50%",
    label: "Product",
    title: "PRODUCT",
    type: "PRODUCT_INVOICE",
    id: 2,
    // color: "#0056B3",
    color: "#2096F3",
    show: true,
  },
  {
    open: true,
    drawerWidth: "50%",
     label: "Package",
     title: "PACKAGE",
    type: "PACKAGE_INVOICE",
    id: 3,
    // color: "#6F42C1",
    color: "#5D35B1",
    show: true,
  },
  {
    open: true,
    drawerWidth: "50%",
    label: "Membership",
    title: "MEMBERSHIP",
    type: "MEMBERSHIP_INVOICE",
    id: 4,
    color: "#E03C4C",
    show: client?.wallets ? false : true,
  },
];

// const PackageRedeemDrawer = {
//   open: true,
//   drawerWidth: "50%",
//    label: "Redeem",
//   title: "PACKAGE REDEEM",
//   type: "PACKAGE_REDEEM_INVOICE",
//   id: 0,
//   color: "#eb6e17",
// };

const CategoryItem = ({ item, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const renderIcon = () => {
    const iconMap = {
      PACKAGE_REDEEM_INVOICE: <img src={redeem} alt="Redeem" width={27} />,
      SERVICE_INVOICE: <img src={Service} alt="service" width={27} />,
      PRODUCT_INVOICE: <img src={Products} alt="products" width={29} />,
      PACKAGE_INVOICE: <img src={Package} alt="package" width={29} />,
      MEMBERSHIP_INVOICE: <img src={Membership} alt="membership" width={48}/>,
    };
    return <>{iconMap[item.type]}</>;
  };

  return (
    <Tooltip title={item.title}>
      <div
        key={item.id}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          height: "50px",
          width: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding:isHovered ? '26px 20px 26px 20px':'29px 0 25px 0',
          border: isHovered ? "1px solid #ddd" : `1px solid ${item.color}`,
          color: item.color,
          cursor: "pointer",
          borderRadius: "5px",
          fontWeight: "600",
          backgroundColor: "#ffffff",
          flexDirection: "column",
          transition: "background-color 0.3s, color 0.3s",
          boxShadow: isHovered
            ? "0 4px 8px rgba(0, 0, 0, 0.2)"
            : "0 1px 3px rgba(0, 0, 0, 0.1)",
            

        }}
      >
        <span>{renderIcon()}</span>
        <span style={{ fontSize: "10px" }}>{item.label}</span>
      </div>
    </Tooltip>
  );
};

function CategoryTypeComponent({
  ourCategoryId,
  handleInvoice,
  setDrawer,
  packageRedeemOptions,
  client,
}) {
  const CATEGORIES = DRAWER_ARRAY(client, packageRedeemOptions)?.filter(
    (item) => item.show
  );

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = (item) => {
    if (client) {
      handleInvoice("drawer", item);
      setDrawer(item);
    } else {
      setErrorMessage("Please Select Client First");
      setOpen(true);
    }
  };

  return (
    <>
      <DynamicErrorPopup
        {...{ open, setOpen, errorMessage, setErrorMessage }}
      />
      {/* <Toast message={"select client"} isOpen={true} onClose={() => {}} /> */}
      <div style={{ display: "flex", gap: "12px", marginTop: "4px" }}>
        {CATEGORIES?.map((item) => (
          <CategoryItem
            key={item.id}
            item={item}
            onClick={() => handleClick(item)}
          />
        ))}
      </div>
    </>
  );
}

export default CategoryTypeComponent;
