import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// project imports
import { ThemeMode } from '../../../config';
import SubCard from '../../../ui-component/cards/SubCard';
import { useCountyPrice } from 'components/hooks/useCountyPrice';
import EditDiscount from './EditDiscount';
import { useEffect, useState } from 'react';

// ==============================|| INVOICE - AMOUNT CARD ||============================== //

function AmountCard({ allAmounts, formik }) {
    const theme = useTheme();
    const { format } = useCountyPrice();

    useEffect(() => {
        onHandleDiscount(formik?.values?.discount, formik?.values?.discountedMode);
    }, [allAmounts])
    
    const onHandleDiscount = (value, unit) => {
        if(value){
            if(unit === 'percentage'){
                formik.setFieldValue('discountedValue',  Math.round(allAmounts.totalAmount - ((allAmounts.totalAmount * value) / 100))) 
            }else{
                formik.setFieldValue('discountedValue', Math.round(allAmounts.totalAmount - value)) 
            }
        }
    }

    return (
        <SubCard className="amount-card-wrap" sx={{ bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'primary.light' }}>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                            
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography align="right" color="primary" variant="subtitle1">
                                        MRP :
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography align="right" color="primary" variant="subtitle1">
                                        {format(Math.round(allAmounts.totalAmount * 100) / 100)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}  justifyContent="flex-end" alignItems="flex-end">
                            <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end">
                                <Grid item xs={6}>
                                    <Typography align="right" color="primary" variant="subtitle1">
                                        Discount :
                                    </Typography>
                                </Grid>
                                {/* <Grid container item xs={2} justifyContent="flex-start" alignItems="flex-end">
                                </Grid> */}
                                <Grid container item xs={6} justifyContent="flex-end" alignItems="flex-end">
                                       <EditDiscount onHandleDiscount={onHandleDiscount} formik={formik}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography align="right" color="primary" variant="subtitle1">
                                    Package Price :
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography align="right" color="primary" variant="subtitle1">
                                        {formik?.values?.discount === 0 ? format(Math.round(allAmounts.totalAmount * 100) / 100) : format(formik.values.discountedValue)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SubCard>
    );
}

AmountCard.propTypes = {
    allAmounts: PropTypes.object
};

export default AmountCard;
