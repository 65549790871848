import PropTypes from 'prop-types';
import { useState,useEffect } from 'react';

// material-ui
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// third party
import { Chance } from 'chance';
import dayjs from 'dayjs';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import { ImagePath, getImageUrl } from 'utils/getImageUrl';

// assets
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { emailSchema, genderSchema, nameSchema, phoneSchema} from 'utils/validation';
// packages import
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { useCompanyData } from 'components/hooks/useCompanyData';
import { addCustomer, updateCustomer } from 'store/customer/slice';
import { selectCountryList } from 'store/meta/selector';
import Loader from 'ui-component/Loader';
import { dispatch, useAppSelector, useAppSelector as useSelector } from 'store';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormattedMessage } from 'react-intl';
import { DialogActions } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { v4 as uuid } from 'uuid'
// ==============================|| ADD/EDIT CLIENT - BODY ||============================== //

const AddContactDialogContent = ({ row, handleToggleAddDialog,handleSelectClient,selectedClient,inputValue }) => {
    const [selectedCustomer, setSelectedCustomer] = useState(selectedClient || {});
    const [value, setValue] = useState(dayjs());
    const [value2, setValue2] = useState(dayjs());
    const customerState = useSelector(state => state.customer);
    const {isApiFulfilled} = useSelector(state => state.customer);
    const isExisting = customerState.isEditing;
    const [loading, setLoading] = useState(false);
    const companyId = useCompanyData();
    const countryList = useAppSelector(selectCountryList) ?? [];
    const chance = new Chance();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [openMoreDataTab, setOpenMoreDataTab] = useState(false);
   
    useEffect(() => {
        if (selectedClient) {
            setSelectedCustomer(selectedClient);
        }
    }, [selectedClient]);

    const validationSchema = Yup.object({
        email: emailSchema(),
        firstName: nameSchema("First Name"),
        // lastName: nameSchema("Last Name"),
        phoneNumber: phoneSchema(),
        gender: genderSchema(),

    });
    const getInitialValues=(selectedCustomer)=>{
        const address =selectedCustomer?.billingAddresses?.[0] || {};
        return {
         firstName: selectedCustomer?.firstName || '',
            lastName: selectedCustomer?.lastName || '',
            email: selectedCustomer?.email || '',
            phoneNumber: inputValue || row?.phoneNumber || "",
            gender: selectedCustomer?.gender || 'male',
            dateOfBirth: selectedCustomer?.dateOfBirth || '',
            anniversary: selectedCustomer?.anniversary || '',
            comment: selectedCustomer?.comment || '',
            permanent_street: address?.street || '',
            permanent_city: address?.city || '',
            permanent_state: address?.state || '',
            permanent_zipcode: address?.postal || '',
            permanent_country: address?.country?.id || countryList.find((c) => c.slug === 'IN')?.id || '',
          };
         
    }
      
    const formik = useFormik({
        initialValues : getInitialValues(selectedCustomer),
        validationSchema: validationSchema,
        onSubmit: (values , {setSubmitting}) => {
            setLoading(true);
                const body = {
                    ...(selectedCustomer && { id: selectedCustomer.id }),
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    ...(values?.email && { email: values?.email }),
                    phoneNumber: values?.phoneNumber,
                    ...(values?.dateOfBirth && {
                        dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
                    }),
                    gender: values?.gender,
                    ...(values?.anniversary && {
                        anniversary: dayjs(values.anniversary).format("YYYY-MM-DD"),
                    }),
                     comment: values?.comment,
                    //  primaryAddress: {
                    //     ...(selectedCustomer && {
                    //         addressId: selectedCustomer?.billingAddresses?.[0]?.id,
                    //       }),
                    //     isMainAddress: true,
                    //     city: values?.permanent_city || "",
                    //     postal: values?.permanent_zipcode || "",
                    //     state: values?.permanent_state || "",
                    //     region: values?.permanent_state,
                    //     street: values?.permanent_street || "",
                    //     addressType: "residential",
                    //     countryId: values?.permanent_country,
                    // },
                };
                if (row) {
                    // Dispatch updateCustomer if row exists (edit case)
                    dispatch(updateCustomer({ id: companyId, payload: body })).then(() => {
                        setLoading(false);
                        setSubmitting(false);
                        handleToggleAddDialog(); // Close dialog after successful API call
                    }).catch((err) => {
                        setLoading(false);
                        setSubmitting(false);
                    });
                } else {
                    dispatch(addCustomer({ id: companyId, payload: body , handleSelectClient})).then(() => {
                        setLoading(false);
                        setSubmitting(false);
                        handleToggleAddDialog(); // Close dialog after successful API call
                    }).catch((err) => {
                        setLoading(false);
                        setSubmitting(false);
                    });
                }
            },
        });
    
    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue  ,setSubmitting} = formik;    
    return (
        <>
        {loading && <Loader />}
        <FormikProvider value={formik} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent sx={{ p: 0 }}>
                        <Grid container spacing={1} p={2.5}>
                            {/* <Grid item xs={12}> */}
                                {/* <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={2.5}
                                    alignItems="center"
                                    justifyContent={{ xs: 'space-between' }}
                                > */}
                                    {/* <Stack alignItems="center" spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                                        <Avatar
                                            alt="User 1"
                                            size="xl"
                                            src={getImageUrl(`avatar-${Math.floor(Math.random() * 9) + 1}.png`, ImagePath.USERS)}
                                        />
                                        <Stack spacing={0.5} alignItems="center">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                sx={{ px: 5.5, py: 1 }}
                                                startIcon={<AddPhotoAlternateOutlinedIcon />}
                                            >
                                                Upload Profile
                                            </Button>
                                            <Typography variant="caption">
                                                <ErrorTwoToneIcon sx={{ height: 16, width: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                                                Image size should be 125kb Max.
                                            </Typography>
                                        </Stack>
                                    </Stack> */}
                                    {/* <FormControlLabel control={<Switch />} label="Active" /> */}
                                {/* </Stack> */}
                            {/* </Grid> */}
                            <Grid item container xs={12} spacing={2.5} my={1}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label={<FormattedMessage id="client.section.add-client.field.firstName" defaultMessage="First Name"/>}
                                       // //placeholder={<FormattedMessage id="client.section.add-client.//placeholder.lastName" defaultMessage="Enter First Name"/>}
                                        defaultValue={row && row.firstName}
                                        value={values.firstName}
                                        {...getFieldProps('firstName')}
                                        error={Boolean(touched.firstName && errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                        fullWidth
                                        label={<FormattedMessage id="client.section.add-client.field.lasttName" defaultMessage="Last Name"/>}
                                        //placeholder={<FormattedMessage id="client.section.add-client.//placeholder.lastName" defaultMessage="Enter Last Name"/>}
                                        defaultValue={row && row.lastName}
                                        value={values.lastName}
                                        {...getFieldProps('lastName')}
                                        error={Boolean(touched.lastName && errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label={<FormattedMessage id="client.section.add-client.field.phone" defaultMessage="Phone number"/>}
                                        //placeholder={<FormattedMessage id="client.section.add-client.//placeholder.email" defaultMessage="Enter Last Name"/>}
                                        defaultValue={row && row.phoneNumber}
                                        value={values.phoneNumber}
                                        {...getFieldProps('phoneNumber')}
                                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Gender"
                                            value={values?.gender }
                                            name="gender"
                                            onChange={(newValue) => setFieldValue('gender', newValue)}
                                            {...getFieldProps('gender')}
                                        
                                        >
                                            <MenuItem value="male">Male</MenuItem>
                                            <MenuItem value="female">Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label={<FormattedMessage id="client.section.add-client.field.email" defaultMessage="Email Id"/>}
                                        //placeholder={<FormattedMessage id="client.section.add-client.//placeholder.email" defaultMessage="Enter Last Name"/>}
                                        defaultValue={row && row.email}
                                        value={values.email}
                                        {...getFieldProps('email')}
                                    />
                                </Grid>
                                
                                {/* <Grid item xs={12} sm={6}>

                                    
                                     <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={1} label="Tag">
                                            <MenuItem value="">Select Tag</MenuItem>
                                            <MenuItem value={1}>Male</MenuItem>
                                            <MenuItem value={2}>Standard</MenuItem>
                                            <MenuItem value={3}>Normal</MenuItem>
                                        </Select>
                                    </FormControl> 
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                                    <DesktopDatePicker
                                            label={<FormattedMessage id="client.section.add-client.field.dob" defaultMessage="Date of Birth"/>}
                                            slotProps={{
                                                textField: {
                                                    fullWidth:true,
                                                    error: false
                                                }
                                            }}
                                            format="dd/MM/yyyy"
                                            value={new Date(values.dateOfBirth)}
                                            onChange={(newValue) => setFieldValue('dateOfBirth', newValue)} 
                                            // {...getFieldProps('dateOfBirth')}
                                        />
                                    {/* </LocalizationProvider> */}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DesktopDatePicker
                                            label={<FormattedMessage id="client.section.add-client.field.anniversary" defaultMessage="Marriage Anniversary "/>}
                                            slotProps={{
                                                textField: {
                                                    fullWidth:true,
                                                    error: false
                                                }
                                            }}
                                            format="dd/MM/yyyy"
                                            value={new Date(values.anniversary)}
                                            onChange={(newValue) => setFieldValue('anniversary' , newValue)}
                                        />
                                </Grid>
                            </Grid>
                           {!openMoreDataTab && <Button onClick={()=>setOpenMoreDataTab(true)}> + Add More Information</Button>}
                           {openMoreDataTab && 
                            <> 
                                <Grid item xs={12} my={1.5}>
                                    <Divider />
                                </Grid>
                                    {/* <Grid item container xs={12} spacing={2.5}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Profession Information</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="First Name"
                                                //placeholder="Enter First Name"
                                                defaultValue={row && row.name.slice(0, -2)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField fullWidth label="Last Name" //placeholder="Enter Last Name" defaultValue={row && chance.last()} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField fullWidth label="Email Id" //placeholder="Enter Email" defaultValue={row && chance.email()} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} my={1.5}>
                                        <Divider />
                                    </Grid> */}
                                <Grid item container xs={12} spacing={2.5}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Address Information</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label2">Country</InputLabel>
                                            <Select labelId="demo-simple-select-label2" id="demo-simple-select"
                                            name="permanent_country"
                                            //   value={values?.permanent_country ? values.permanent_country : ''}
                                               {...getFieldProps('permanent_country')} label="Country">
                                                <MenuItem value="">Select Country</MenuItem>
                                                {countryList?.map((i,index)=> <MenuItem value={i?.id} key={uuid()}>{i?.title}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label2">State</InputLabel> */}
                                            <TextField labelId="demo-simple-select-label2"
                                             id="demo-simple-select"
                                             name="permanent_state"
                                             {...getFieldProps('permanent_state')}
                                            //  value={values.state}
                                             label="State">
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                         fullWidth 
                                           name="permanent_city"
                                            label="City"
                                            placeholder="Enter City" 
                                            defaultValue={row && row.city} 
                                            {...getFieldProps('permanent_city')} 
                                         />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField fullWidth label="Zip/Postal Code" placeholder="Enter Zip/Postal Code" {...getFieldProps('permanent_zipcode')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="permanent_street"
                                            id="outlined-multiline-flexible"
                                            label="Address"
                                            {...getFieldProps('permanent_street')}
                                            //placeholder="address"
                                            multiline
                                            rows={3}
                                            defaultValue={row && row.permanent_street}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} my={1.5}>
                                    <Divider />
                                </Grid>
                                    {/* <Grid item container xs={12} spacing={2.5}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Contact Information</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField fullWidth label="Phone no" placeholder="Enter Phone" defaultValue={row && chance.phone()} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField fullWidth label="Mobile no" placeholder="Enter Mobile" defaultValue={row && row.phoneNumber} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="end">
                                                                <FacebookIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    value={row && chance.email()}
                                                    //placeholder="Enter Email"
                                                />
                                                <Button variant="contained" size="small" color="secondary">
                                                    Connect
                                                </Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="end">
                                                                <TwitterIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    defaultValue={row && chance.email()}
                                                    //placeholder="Enter Email"
                                                />
                                                <Button variant="contained" size="small" color="secondary">
                                                    Connect
                                                </Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="end">
                                                                <LinkedInIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    defaultValue={row && chance.email()}
                                                    //placeholder="Enter Email"
                                                />
                                                <Button variant="contained" size="small" color="secondary">
                                                    Connect
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} my={1.5}>
                                        <Divider />
                                    </Grid> */}
                                <Grid item container xs={12} spacing={2.5}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Other Details</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="comment"
                                            id="outlined-multiline-flexible"
                                            label="Notes"
                                            value={values.comment}
                                            {...getFieldProps('comment')}
                                            //placeholder="notes"
                                            multiline
                                            defaultValue={row && row.comment}
                                            rows={3}
                                        />
                                    </Grid>
                                </Grid>
                            </>}
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ p: 3 }}>
                        <Stack spacing={1.5} direction="row" justifyContent="flex-end">
                            <AnimateButton >
                                <Button onClick={handleToggleAddDialog} variant="outlined">
                                    Cancel
                                </Button>
                            </AnimateButton>
                            <AnimateButton>
                                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                                       Save
                                    </Button>
                                </AnimateButton>
                        </Stack>
                    </DialogActions>
                </Form>
                </LocalizationProvider>
            </FormikProvider>
            </>
    );
};

AddContactDialogContent.propTypes = {
    row: PropTypes.object,
    handleSelectClient:PropTypes.func,
};

export default AddContactDialogContent;
