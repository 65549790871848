

export const PrivatePath = {
  home: "/home",
  create: "/create-appointment",
  services: "/services",
  staff: "/staff",
  attendence:"/attendence",
  analytics:"/analytics-reports",
  customerDetails:"/customer-details",
  manageStores:"/manage-stores",
  pos:"/pos",
  billing:'/create-billing',
  invoice:'/invoices/',
  create_invoice:'/invoices/create-invoice',
  generate_invoice:'/invoices/generate_invoice',
  invoice_details:'/invoices/invoice-details',
  invoice_edit:'/invoices/edit-invoice',
  view_invoice_details:'/invoices/view-invoice-details'
};
export const PublicPath = {
  login: "/login",
  termsCondition: "/terms-conditions",
  register: "/sign-up",
  userVerify:"/user-verify",
  forgotPassword:"/forgot-password",
  resetPassword:"/reset-password",
  invoice:"/invoice",
};
