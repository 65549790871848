import PropTypes from 'prop-types';

// material-ui
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// assets
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useCountyPrice } from 'components/hooks/useCountyPrice';
import { Paper ,styled} from '@mui/material';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    border: '1px solid #e0e0e0', // Light grey border around the table
    borderRadius: '12px', // Rounded corners for the container
    overflow: 'hidden', // Ensures that the table remains within the container
    // boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow for a nice effect
  }));
  
  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#e3e8ef', // Custom header background color (blue)
    color: '#fff', // White text for the header
    fontWeight: 'bold', // Bold text
    fontSize: '16px', // Increase font size for the header
    textAlign: 'center', // Center the text in the header
    padding: theme.spacing(2), // Increase padding for a cleaner look
  }));
  
  const CustomBodyCell = styled(TableCell)(({ theme }) => ({
    textAlign: 'center', // Center text in the body as well
    borderBottom: '1px solid #e0e0e0', // Light gray border between rows
    borderRight: '1px solid #e0e0e0',
    padding: theme.spacing(1.5), // Consistent padding for table cells
  }));

// ==============================|| CREATE PACKAGE - ITEMS ||============================== //

function ItemList({ productsData, deleteProductHandler, hideDeleteButton =false}) {
  const { format } = useCountyPrice();

  return (
    <CustomTableContainer component={Paper}>
      <Table className="inv-inn-table">
        <TableHead>
          <TableRow>
            <CustomTableCell sx={{ pl: 3 }}>Item Name</CustomTableCell>
            <CustomTableCell align="right">Quantity</CustomTableCell>
            <CustomTableCell align="right">Amount</CustomTableCell>
            <CustomTableCell align="right">Total</CustomTableCell>
            <CustomTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!productsData ||
            (productsData.length <= 0 && (
              <TableRow>
                <CustomBodyCell colSpan={6} align="center">
                  Now Rows
                </CustomBodyCell>
              </TableRow>
            ))}
          {productsData.map((row, index) => (
            <TableRow key={index}>
              <CustomBodyCell sx={{ pl: 3 }}>
                <Typography variant="subtitle1">{row.title}</Typography>
                <Typography variant="body2">{row.description}</Typography>
              </CustomBodyCell>
              <CustomBodyCell align="right">{row.quantity}</CustomBodyCell>
              <CustomBodyCell align="right">
                {format(row.amount)}
              </CustomBodyCell>
              <CustomBodyCell align="right">
                {format(Math.round(row.total * 100) / 100)}
              </CustomBodyCell>

              <CustomBodyCell sx={{ pr: 1 }} align="right">
          {!hideDeleteButton&&      <IconButton
                  color="error"
                  size="small"
                  onClick={() => deleteProductHandler(row)}
                  aria-label="'Delete'"
                >
                  <DeleteTwoToneIcon fontSize="small" />
                </IconButton>}
              </CustomBodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
}

ItemList.propTypes = {
    productsData: PropTypes.array,
    deleteProductHandler: PropTypes.func
};

export default ItemList;
