import { useState } from "react";

// material-ui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// project imports

import { useAppSelector as useSelector } from "../../../store";

// assets
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export const TopupMembershipForm = ({
  open,
  onClose,
  selectedClient,
  handleAddItem,
}) => {
  console.log(selectedClient, "selectedClient");
  const employees = useSelector((state) => state.employees.employeeList) || [];
  const [selectedMembership, setSelectedMembership] = useState(null);

  const onClickTopup = (data) => {
    const topupMembershipBody = {
      id: data?.membershipPlan?.id,
      title: data?.membershipPlan?.title,
      description: "",
      selectedQuantity: 1,
      price: selectedMembership?.topupAmount,
      selectedDiscount: 0,
      totalAmount: selectedMembership?.topupAmount,
      customerServicePackage: [],
      assignedStaffs: selectedMembership?.assignedStaffs,
      selectedCategoryType: "topup wallet",
      topupValue: data?.topupValue,
    };

    handleAddItem(topupMembershipBody);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      sx={{ "& .MuiDialog-paper": { maxWidth: "100%", width: 800 } }}
    >
      <Grid item xs={12}>
        <TableContainer>
          <Table sx={{ minWidth: 800 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableCell> Membership Name</TableCell>
                <TableCell> Wallet Amount</TableCell>
                <TableCell> Amount</TableCell>
                <TableCell> Topup Value</TableCell>
                <TableCell> Select Staff</TableCell>
                <TableCell> Topup</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedClient?.membershipPlansRltn?.map((i) => {
                // const pricePaid = Number(i?.membershipPlan?.price);
                const membershipPercentage = Number(
                  i?.membershipPlan?.percentage
                );
                // const walletAmount =
                //   pricePaid +
                //   Math.round((pricePaid * membershipPercentage) / 100);

                // Topup Value Calculation
                const topupFieldValue = Number(selectedMembership?.topupAmount);
                const topupValue = selectedMembership?.topupAmount
                  ? topupFieldValue +
                    Math.round((topupFieldValue * membershipPercentage) / 100)
                  : 0;
                return (
                  <TableRow>
                    <TableCell> {i?.membershipPlan?.title}</TableCell>
                    <TableCell>{selectedClient.wallets.balance}</TableCell>
                    <TableCell>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Amount"
                          onChange={(e) => {
                            const amountSelected = {
                              ...selectedMembership,
                              topupAmount: e.target.value,
                            };
                            setSelectedMembership(amountSelected);
                          }}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell>{topupValue}</TableCell>

                    <TableCell>
                      <Grid item xs={12}>
                        <Autocomplete
                          className="staff-autocomplete"
                          options={employees}
                          getOptionLabel={(option) => {
                            return `${option?.firstName} ${option?.lastName}`;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Please Select"
                            />
                          )}
                          onChange={(e, v, r) => {
                            const data = {
                              ...selectedMembership,
                              assignedStaffs: [
                                {
                                  ...v,
                                  employee: {
                                    firstName: v?.firstName,
                                    lastName: v?.lastName,
                                  },
                                  employeeId: v?.id,
                                  id: v?.id,
                                },
                              ],
                            };
                            setSelectedMembership(data);
                          }}
                          sx={{ width: 200 }}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          onClickTopup({ ...i, topupValue });
                        }}
                        disabled={
                          !selectedMembership?.assignedStaffs ||
                          !selectedMembership?.topupAmount
                        }
                      >
                        Topup
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Dialog>
  );
};
