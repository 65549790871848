import {
  Add,
  Delete,
  // Edit,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";

const InvoiceItemsTable = ({
  rows,
  setInvoiceDetails,
  // setInvoiceDetailsItem,
  // setDrawer,
}) => {
  const { format } = useCountyPrice();

  const commonButtonStyle = {
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    color: "white",
    cursor: "pointer",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const updateItemQuantity = ({ increment, uuid }) => {
    const newValue = rows?.map((item) => {
      if (item.uuid === uuid) {
        const updatedQuantity = increment
          ? item.itemQuantity + 1
          : item.itemQuantity - 1;
        return {
          ...item,
          itemQuantity: updatedQuantity,
          total: updatedQuantity * item.itemAmount,
        };
      } else {
        return item;
      }
    });
    setInvoiceDetails(newValue);
  };
  const columns = [
    {
      field: "action",
      headerName: "Action",
      maxWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            {/* <Edit
              onClick={() => {
                setDrawer({
                  open: true,
                  drawerWidth: "50%",
                  title: "MEMBERSHIP",
                  type: "MEMBERSHIP_INVOICE",
                  showIcon: "",
                });
                setInvoiceDetailsItem(row);
              }}
            /> */}
            <Tooltip title="DELETE">
              <Delete
                sx={{
                  marginTop: "5px",
                  color: "#C60239",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setInvoiceDetails((prev) => {
                    return prev?.filter((item) => item.uuid !== row.uuid);
                  });
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "itemName",
      headerName: "Item Name",
      minWidth: 180,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>{row?.currItem?.title}</div>;
      },
    },
    {
      field: "itemQuantity",
      headerName: "Quantity",
      maxWidth: 110,
      flex: 1,
      renderCell: ({ row }) => {
        const itemQuantity = row.itemQuantity;
        const uuid = row?.uuid;
        // const drawer = row?.drawer;
        const type = row?.drawer?.type;
        const disabled =
          type === "PACKAGE_REDEEM_INVOICE" ||
          type === "MEMBERSHIP_TOPUP_INVOICE";
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <button
              style={{
                ...commonButtonStyle,
                background: disabled ? "#ccc" : "#FF3F3F",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
              disabled={disabled || itemQuantity <= 1}
              onClick={() => updateItemQuantity({ increment: false, uuid })}
            >
              <RemoveIcon />
            </button>

            {itemQuantity}

            <button
              style={{
                ...commonButtonStyle,
                background: disabled ? "#ccc" : "#31DE83",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
              disabled={disabled}
              onClick={() => updateItemQuantity({ increment: true, uuid })}
            >
              <Add />
            </button>
          </div>
        );
      },
    },
    {
      field: "itemAmount",
      headerName: "Amount",
      maxWidth: 100,
      flex: 1,
      renderCell: ({ row: { itemAmount } }) => {
        return <div>{format(itemAmount)}</div>;
      },
    },
    {
      field: "assignedStaff",
      headerName: "Assigned Staff",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        return (
          <div>
            {row?.employees && row?.employees?.length > 0
              ? row?.employees?.map((item) => item?.firstName).join(",")
              : "N/A"}
          </div>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 120,
      renderCell: ({ row }) => {
        return <div>{format(row?.total)}</div>;
      },
    },
  ];
  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={({ uuid }) => uuid}
        rowHeight={40}
        columnHeaderHeight={45}
        hideFooter
        sx={{
          border: "1px solid #ccc",
          ".MuiDataGrid-columnHeader": {
            backgroundColor: "#9469FC",
            color: "#fff",
          },
        }}
        disableColumnMenu
        disableColumnSelector
        disableColumnSorting
      />
    </>
  );
};

export default InvoiceItemsTable;
