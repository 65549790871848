// material-ui
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ThreeDots } from 'react-loader-spinner'
// ==============================|| LOADER ||============================== //

const Loader = () => (



    <>

        <Box sx={{ position: 'fixed', top: 0, left: 0, zIndex: 1301, width: '100%' }}>
            <LinearProgress color="primary" />
        </Box>
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly transparent white overlay
                zIndex: 1000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#fa27f0"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </Box>
    </>
);

export default Loader;
