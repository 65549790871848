import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
// third-party
import Paper from "@mui/material/Paper";

import { gridSpacing } from "utils/constantString";
import dayjs from "dayjs";
import { useCountryCurrency } from "components/hooks/useCountryCurrency";
import { useAppSelector } from "store";
import { useSelector } from "react-redux";
import { capitalize } from "utils/general";
import "./new-invoice-style.css";

// table data

// ==============================|| INVOICE DETAILS - INVOICE ||============================== //

const invoiceStatus = {
  pending: "Pending",
  completed: "Paid",
  cancel: "Cancelled",
};

const ViewInvoiceDetails = ({ invoiceDetail }) => {
  const theme = useTheme();
  const componentRef = useRef(null);
  const { ownerData } = useAppSelector((state) => state.authentication);
  const profileImage =
    ownerData?.ownerBusiness?.businessLocations?.[0]?.companyImageUrl;
  const [searchParams, setSearchParams] = useSearchParams();
  const { invoices } = useSelector((state) => state.invoice);
  const navigate = useNavigate();
  const currencySymbol = useCountryCurrency();
  const [invoiceData, setInvoiceData] = useState();

  useEffect(() => {
    if (!invoiceDetail) {
      const data = invoices?.find?.((i) => i?.id === searchParams?.get("id"));
      setInvoiceData(data);
    } else {
      setInvoiceData(invoiceDetail);
    }
  }, []);

  const getTotal = () => {
    if (!invoiceData) {
      return;
    }
    const finalPrice = parseFloat(invoiceData?.netPrice);
    const taxAmount =
      finalPrice * (Math.round(invoiceData?.tax?.rate * 100) / 100 / 100);

    const discount =
      (finalPrice + taxAmount) *
      (Math.round(invoiceData?.discount?.rate * 100) / 100 / 100);

    const total = finalPrice + taxAmount - discount;

    return Math.round((total + Number.EPSILON) * 100) / 100;
  };
  const getSubTotal = () => {
    if (!invoiceData) {
      return;
    }
    const subtotal = parseFloat(invoiceData?.netPrice);
    return Math.round((subtotal + Number.EPSILON) * 100) / 100;
  };

  const getTax = () => {
    if (!invoiceData) {
      return;
    }
    const tax =
      invoiceData?.netPrice *
      (Math.round(invoiceData?.tax?.rate * 100) / 100 / 100);

    return Math.round((tax + Number.EPSILON) * 100) / 100;
  };

  const address = invoiceData?.company?.companyAddress || {};
  const getItemDiscount = (item) => {
    let discountVal = `${currencySymbol}${item.itemDiscount}`;
    if (item.itemDiscountType == "percentage") {
      discountVal = `${item.itemDiscount}%`;
    }
    return discountVal;
  };

  const paymentpending = invoiceData?.invoicePaymentMethods.find((item) => {
    return item.status === "pending";
  });

  const ServiceDoneBy = (val) => {
    if (val?.length > 0) {
      return val.map((item, index) => (
        <div key={index}>
          {`${item?.employeeMetaData?.firstName} ${item?.employeeMetaData?.lastName}`}
        </div>
      ));
    } else {
      return <div>N/A</div>;
    }
  };
  const extractRevenue = (val) => {
    if (val && val?.length > 0) {
      return val.map((item, index) => (
        <div key={index}>{`${item?.revenue || 0}`}%</div>
      ));
    }
    return <div>N/A</div>;
  };
  return (
    <Grid
      container
      justifyContent="center"
      spacing={gridSpacing}
      sx={{ paddingTop: "20px" }}
    >
      <Grid
        item
        xs={12}
        md={10}
        lg={10}
        ref={componentRef}
        style={{ position: "relative" }}
      >
        <div className="view-track-inner">
          <div className="track-center">
            <div className="tax-invoice-dv">
              {/* <div className="text-center">
            <Typography className="text-heading mb-2">TAX INVOICE</Typography>
          </div>

          <div>
            <p className="text-para mb-0 pb-0">
              <strong>INVOICE NO:</strong> {invoiceData?.invoiceNo}
            </p>
          </div> */}

              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <p className="text-para mb-1 pb-0">
                    <strong>Customer Name:</strong>
                  </p>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: ".85rem",
                      marginBottom: "7px",
                    }}
                  >
                    {invoiceData?.client?.firstName}{" "}
                    {invoiceData?.client?.lastName}
                  </Typography>
                  <p className="text-para mb-1 pb-0">
                    <strong>Mobile No:</strong>
                  </p>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: ".85rem",
                      marginBottom: "7px",
                    }}
                  >
                    {invoiceData?.client?.phoneNumber}
                  </Typography>
                  <p className="text-para mb-1 pb-0">
                    <strong>Email ID:</strong>
                  </p>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: ".85rem",
                      marginBottom: "7px",
                    }}
                  >
                    {invoiceData?.client?.email
                      ? invoiceData?.client?.email
                      : "N/A"}
                  </Typography>
                  <p className="text-para mb-1 pb-0">
                    <strong>Date &amp; Time:</strong>
                  </p>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: ".85rem",
                      marginBottom: "7px",
                    }}
                  >
                    {invoiceData?.invoiceDate}
                  </Typography>
                </Grid>

                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <p
                    style={{ maxWidth: "100%" }}
                    className="text-para mb-1 pb-0"
                  >
                    <strong>Payment Mode:</strong>
                  </p>
                  {invoiceData?.invoicePaymentMethods?.map(
                    ({ mode, amount }, id) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                            gap: "15px",
                          }}
                          key={id}
                        >
                          <Typography
                            style={{ fontWeight: 600, fontSize: ".85rem" }}
                          >
                            {mode || "N/A"}:
                          </Typography>
                          <Typography
                            style={{ fontWeight: 400, fontSize: ".85rem" }}
                          >
                            {currencySymbol} {amount}
                          </Typography>
                        </div>
                      );
                    }
                  )}
                </Grid>
              </Grid>
              <TableContainer component={Paper} className="table-view-cont">
                <Table
                  aria-label="simple table"
                  className="inv-inn-table"
                  sx={{ border: "1px solid rgb(238 238 238)" }}
                >
                  <TableHead sx={{ background: "#EDE7F6" }}>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell>Staff</TableCell>
                      <TableCell>Revenue</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData?.invoiceDetails?.map((row, index) => (
                      <TableRow
                        key={row?.itemTitle}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row?.itemTitle} x {row?.itemQuantity}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {currencySymbol} {row?.itemPrice}
                        </TableCell>

                        <TableCell style={{ maxWidth: "12rem" }}>
                          {ServiceDoneBy(row?.employees)}
                        </TableCell>
                        <TableCell>
                        {extractRevenue(row?.employees)}
                        </TableCell>
                        <TableCell align="right">
                          {currencySymbol}
                          {row?.itemPrice}
                          {/* {row.itemAmount} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Typography className="text-para mb-1 pb-0">
                    <strong>Subtotal</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography className="text-para mb-1 pb-0">
                    {" "}
                    {currencySymbol}
                    {invoiceData?.grossPrice}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Typography className="text-para mb-1">
                    <strong>Discount</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography className="text-para mb-1">
                    {currencySymbol}
                    {invoiceData?.invoiceDiscount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Typography className="text-para mb-1">
                    <strong>Tax</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography className="text-para mb-1">
                    {currencySymbol}
                    {invoiceData?.invoiceTaxAmount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="mt-2">
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Typography className="text-heading mb-0">
                    <strong>GRAND TOTAL</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography className="text-heading mb-0">
                    {currencySymbol}
                    {invoiceData?.netPrice}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="mt-2">
                <Grid item xs={6} style={{ textAlign: "left" }}>
                  <Typography className="text-heading mb-0">
                    <strong>Pending Amount</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography className="text-heading mb-0">
                    {currencySymbol}{" "}
                    {paymentpending?.amount ? paymentpending?.amount : 0}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ViewInvoiceDetails;
