import { FormControl, Grid, InputAdornment, TextField } from "@mui/material";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentDrawerLeftSection from "./PaymentDrawerLeftSection";
import { addInvoice, getInvoiceData } from "store/invoice/slice";
import { useCompanyData } from "components/hooks/useCompanyData";
import { format as dateFormat } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "ui-component/Loader";
import { getCustomers } from "store/customer/slice";

const PaymentDrawer = ({
  client,
  invoiceDetails,
  grossPrice,
  netPrice,
  allAmounts,
  invoiceDate,
  setDrawer,
}) => {


  const { paymentOptions } = useSelector((state) => state.invoice);

  const [form, setForm] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentOptionFilter, setPaymentOptionFilter] =
    useState(paymentOptions);

  const [walletBalance, setWalletBalance] = useState(
    client?.wallets?.balance || 0
  );

  const isWallet = (method) => method?.title?.toLowerCase() === "wallet";

  const walletTopup = invoiceDetails?.find(
    (item) => item.itemType === "topup wallet"
  );

  useEffect(() => {
    if (client?.wallets?.balance && !walletTopup) {
      setPaymentOptionFilter(paymentOptions);
    } else {
      const data = paymentOptions?.filter((i) => i?.title !== "Wallet");
      setPaymentOptionFilter(data);
    }
    const update = paymentOptions?.map((i) => {
      return {
        paymentMethodId: i?.id,
        amount: 0,
        status: "completed",
      };
    });
    setForm(update);
  }, []);

  const { format } = useCountyPrice();

  const paymentMethodsUsed = form?.filter((item) => item.amount !== 0);

  const splitPayment = paymentMethodsUsed?.reduce((a, b) => {
    return (a += b.amount);
  }, 0);

  const pendingAmount = netPrice - splitPayment || 0;

  const [showPendingButton, setShowPendingButton] = useState(true);

  const handleChange = (option, value) => {
    const updateForm = form?.map((i) => {
      if (option?.id === i?.paymentMethodId) {
        return {
          ...i,
          amount: value,
          status: "completed",
          mode: option?.title?.toLowerCase(),
        };
      }
      return i;
    });
    setForm(updateForm);
    if (pendingAmount) {
      setShowPendingButton(true);
    }
  };

  const dispatch = useDispatch();

  const companyId = useCompanyData();

  const navigate = useNavigate();

  const { id } = useParams();

  const customerIsRedeemingPackage = invoiceDetails?.find(
    (item) => item.drawer.type === "PACKAGE_REDEEM_INVOICE"
  );
  const customerIsSellingPackage = invoiceDetails?.find(
    (item) => item.drawer.type === "PACKAGE_INVOICE"
  );
  const customerIsUpdatingWallet = invoiceDetails?.find(
    (item) => item.drawer.type === "MEMBERSHIP_TOPUP_INVOICE"
  );
  const customerIsSellingMembership = invoiceDetails?.find(
    (item) => item.drawer.type === "MEMBERSHIP_INVOICE"
  );

  const createInvoice = async () => {
    const totalbillamount = invoiceDetails?.reduce((a, b) => {
      return (a += (b?.itemAmount * b?.itemQuantity)|| 0);
    }, 0);

    const percentageofdiscount =
      allAmounts?.invoiceDiscount && totalbillamount
        ? Number(allAmounts?.invoiceDiscount) / totalbillamount
        : 0;

    const pendingPayment = {
      paymentMethodId: paymentOptions?.find((i) => i?.title === "Pending")?.id,
      amount: parseFloat(pendingAmount),
      status: "pending",
      mode: paymentOptions?.find((i) => i?.title === "Pending")?.title,
    };

    const payload = {
      ...(id && { id: id }),
      companyId: companyId,
      clientId: client?.id,
      invoiceDiscount: allAmounts?.invoiceDiscount,
      invoiceTaxAmount: allAmounts?.invoiceTaxAmount,
      grossPrice: grossPrice,
      netPrice: netPrice,
      paymentMethod: "cash",
      invoiceTaxRate: 0,
      invoiceDate: dateFormat(invoiceDate, "yyyy-MM-dd"),
      status: "completed",
      invoicePaymentMethod:
        pendingPayment?.amount > 0
          ? [...paymentMethodsUsed, pendingPayment]
          : paymentMethodsUsed,
      invoiceDetails: invoiceDetails?.map((item) => {
        return {
          itemId: item.itemId,
          itemPrice: item.itemAmount,
          itemAmount: item.itemAmount* +item.itemQuantity,
          netAmount:
            +(item.itemAmount) * +item.itemQuantity -
            Math.round(
              percentageofdiscount * +item.itemAmount * +item.itemQuantity
            ),
          itemQuantity: item.itemQuantity,
          itemType: item.itemType,
          itemDiscount: 0,
          useFrmPkg: item?.currItem?.useFrmPkg || false,
          employees: item.employees?.map((emp) => {
            return {
              employeeId: emp?.employeeId,
              revenuePercentage: emp?.revenue,
              commission: emp?.commission,
            };
          }),
        };
      }),
    };
    setIsLoading(true);
    dispatch(addInvoice({ payload: payload })).then((response) => {
      console.log(response, "response");
      if (response?.data?.statusCode === 201) {
        (customerIsRedeemingPackage ||
          customerIsUpdatingWallet ||
          customerIsSellingMembership ||
          customerIsSellingPackage) &&
          dispatch(getCustomers({ companyId }));

        setDrawer({ open: false });
        setIsLoading(false);
        if (id) {
          dispatch(getInvoiceData({ companyId }));
          navigate(`/invoices/invoice-details?id=${response.data.data.id}`);
        } else {
          navigate(`/invoices/invoice-details?id=${response.data.data.id}`);
          return;
        }
      } else {
        // setOpen(true);
        // setErrorMessage(response.message);
        setIsLoading(false);
      }
    });
  };

  return (
    <div style={{ position: "relative", height: "90vh" }}>
      {/* <DynamicErrorPopup
        {...{ open, setOpen, errorMessage, setErrorMessage }}
      /> */}
      {isLoading && <Loader />}
      <Grid container spacing={2}>
        <PaymentDrawerLeftSection
          {...{
            grossPrice,
            allAmounts,
            netPrice,
            paymentMethodsUsed,
          }}
          pendingAmount={pendingAmount}
        />
        <Grid item xs={6}>
          <>
            <div className="checkout-drawer-cont">
              <h3>PAYMENT METHODS</h3>
              {paymentOptionFilter?.map(
                (method, index) =>
                  method?.title !== "Pending" && (
                    <div className="payment-div" key={index}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          placeholder={method.title}
                          type="number"
                          value={form[method.title]}
                          onChange={(e) => {
                            const amount = +e.target.value;
                            handleChange(method, amount);
                            if (isWallet(method)) {
                              setWalletBalance(
                                client?.wallets?.balance - amount
                              );
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {method.title}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {isWallet(method) && (
                          <div
                            style={{
                              marginTop: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingLeft: "5px",
                            }}
                          >
                            <label style={{ color: "gray" }}>
                              Wallet Balance :
                            </label>

                            <span
                              className={
                                walletBalance > 0
                                  ? "wallet-balance"
                                  : "wallet-balance negative"
                              }
                            >
                              {format(walletBalance)}
                            </span>
                          </div>
                        )}
                      </FormControl>
                    </div>
                  )
              )}
            </div>

            {/* Final Button  */}

            <br />
            <div className="create-invoice-button-div">
              {pendingAmount > 0 && showPendingButton ? (
                <button
                  onClick={() => {
                    setShowPendingButton(false);
                  }}
                  className="customButton pending"
                >
                  PROCEED WITH PENDING {pendingAmount}
                </button>
              ) : (
                <button
                  className="customButton"
                  disabled={pendingAmount < 0}
                  onClick={createInvoice}
                >
                  CREATE INVOICE
                </button>
              )}
            </div>
          </>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentDrawer;
