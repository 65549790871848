import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSelector } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectEmployeeAutoComplete({
  invoiceDetailsItemEmployees = [],
  handleChange,
}) {
  const { employeeList } = useSelector((store) => store.employees);

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      limitTags={1}
      value={invoiceDetailsItemEmployees}
      options={employeeList}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.firstName} {option.lastName}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
}
