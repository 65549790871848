// material-ui
import { useTheme } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// ==============================|| EMPTY View ||============================== //

const EmptyView = ({imgDark,  message1 , message2, width=300,padding="80px"}) => {
    const theme = useTheme();
    return (
        <Stack  sx={{ 
            display:'flex',
            justifyContent:'center',
            alignItems: 'center',
            textAlign:'center',
            width:'100%',
             padding:padding,
        }}  spacing={2}>
            <CardMedia
                component="img"
                image={imgDark}
                sx={{ width: width,margin:'0 auto'}}
            />
            <Stack spacing={1}>
                <Typography variant="h2" color="inherit">
                    {message1}
                </Typography>
                <Typography variant="body2">
                    {message2}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default EmptyView;
