import React from "react";
import { Tooltip } from "@mui/material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import "./style.css"

function HelpAndSupportButton() {
  return (
    <Tooltip title="Help and Support" arrow>
      <button
        onClick={() =>
          (window.location.href = "mailto:support@appointimize.com")
        }
        className="help-support-button"
      >
        <ContactSupportIcon className="help-support-button-icon" />
      </button>
    </Tooltip>
  );
}

export default HelpAndSupportButton;
