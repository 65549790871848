import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Button,
  Box,
  InputAdornment,
} from "@mui/material";

function CommisionAndRevenue({
  employees,
  handleInvoice,
  setInvoiceDetails,
  setDrawer,
  drawer,
  setInvoiceDetailsItem,
}) {
  // State to hold the rows of the table (with employee names, and commission and revenue)
  const [rows, setRows] = useState(
    employees?.map((item) => ({
      ...item,
      commission: item.commission,
      revenue: item.revenue,
    }))
  );
  console.log(rows, "rows1212123");
  // Handle input changes for commission and revenue
  const handleInputChange = (e, index, column) => {
    const { value } = e.target;
    const updatedRows = rows?.map((row, i) =>
      i === index ? { ...row, [column]: +value } : row
    );
    setRows(updatedRows);
  };

  const totalRevenue = Math.round(
    rows?.reduce((a, b) => {
      return (a += Number(b?.revenue));
    }, 0)
  );
  return (
    <Box sx={{ padding: 3 }}>
      {/* <h2>Employee Commission and Revenue Table</h2> */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 150 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Commission</TableCell>
              <TableCell align="center">Revenue</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {row.firstName} {row.lastName}
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={row.commission}
                    onChange={(e) => handleInputChange(e, index, "commission")}
                    placeholder="Commission"
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: 150 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">INR</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={row.revenue}
                    onChange={(e) => handleInputChange(e, index, "revenue")}
                    placeholder="Revenue"
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: 150 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Optional Add Row Button */}
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        gap={"1rem"}
        sx={{ marginTop: 2 }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setDrawer({ open: false });
            setInvoiceDetails(null);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setDrawer(drawer);
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={totalRevenue !== 100}
          onClick={() => {
            const newEmployeesArray = rows?.map((item) => {
              return {
                employeeId: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                revenue: item.revenue,
                commission: item.commission,
              };
            });
            const rowData = handleInvoice("employees", newEmployeesArray);
            setInvoiceDetails((prev) => {
              return [...prev, { ...rowData, uuid: new Date() }];
            });
            setDrawer({ open: false });
            setInvoiceDetailsItem(null);
          }}
        >
          ADD TO TABLE
        </Button>
      </Box>
    </Box>
  );
}

export default CommisionAndRevenue;
