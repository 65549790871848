import PropTypes from 'prop-types';
import { useState, forwardRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Popper from '@mui/material/Popper';
import ListItemIcon from '@mui/material/ListItemIcon';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import StoreIcon from '@mui/icons-material/Store';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tooltip from "@mui/material/Tooltip";
// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';
import { ThemeMode } from 'config';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons-react';
import { ListItemSecondaryAction, ListItemText,Typography } from '@mui/material';
import { useAppSelector } from 'store';
import { capitalize } from 'utils/general';

const HeaderAvatar = forwardRef(({ children, ...others }, ref) => {
    const theme = useTheme();

    return (
        <Avatar
            ref={ref}
            variant="rounded"
            sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'secondary.light',
                color: theme.palette.mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                '&:hover': {
                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                    color: theme.palette.mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.light'
                }
            }}
            {...others}
        >
            {children}
        </Avatar>
    );
});

HeaderAvatar.propTypes = {
    children: PropTypes.node
};

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
    const theme = useTheme();

    return (
        <OutlinedInput
            id="input-search-header"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="16px" />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <HeaderAvatar>
                        <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
                    </HeaderAvatar>
                    <Box sx={{ ml: 2 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'orange.light',
                                color: 'orange.dark',
                                '&:hover': {
                                    bgcolor: 'orange.dark',
                                    color: 'orange.light'
                                }
                            }}
                            {...bindToggle(popupState)}
                        >
                            <IconX stroke={1.5} size="20px" />
                        </Avatar>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight', sx: { bgcolor: 'transparent', pl: 0.5 } }}
            sx={{ width: '100%', ml: 0.5, px: 2, bgcolor: 'background.paper' }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| BUSINESS NAME ,BUSINESS LOCATION , BUSINESS HOURS,||============================== //
const BusinessDetails = () => {
    const {ownerData} = useAppSelector(state=>state.authentication);
    const { ownerBusiness} = ownerData;
    const { store } = useAppSelector(state => state.stores);
    const [bussinessTime, setBussinessTime] = useState(null);
    
    const dayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    
    const currentDay = dayNames[new Date().getDay()];
    useEffect(() => {
        const today = store?.schedules?.find((i)=>i?.prefDay.toLowerCase() === currentDay.toLowerCase());
        if(today){
            setBussinessTime(today)
        }
    }, [store])
    

    return (
        <>
             <Grid item xs={12}  style={{margin:"0px 30px", display:"flex",
              flexDirection:"row", gap:"20px"}}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <StoreIcon sx={{ fontSize: '1.5rem', }} /> 
                    <Tooltip arrow title="Business Name">
                        <Typography variant="subtitle1" sx={{fontSize: '16px',  ml: 1.2 }}>{capitalize (ownerBusiness?.businessLocations?.[0]?.title)}</Typography> 
                    </Tooltip>
                </Box>
                <Grid  sx={{ display: 'flex' }}>
                    <Box  sx={{ display: 'flex', alignItems: 'center' ,justify:'center', borderRight:"1px solid #dedede", borderLeft:"1px solid #dedede", pl:2, pr:2}}>
                        <PinDropTwoToneIcon sx={{ fontSize: '1rem' }} />
                        <Tooltip arrow title="Business Location">
                            <Typography variant="h5" sx={{color:'#364152', fontSize: '14px', fontWeight: '500',ml:1.2 }}>{ownerBusiness?.businessLocations?.[0]?.companyAddress?.city}</Typography>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center',ml:2}}>
                        <AccessTimeIcon sx={{ fontSize: '1rem' }} />
                        <Tooltip arrow title="Business Hours"> 
                            <Typography variant="subtitle1" sx={{color:'#364152', fontSize: '14px', ml: 1.2 }}> {bussinessTime? `${bussinessTime?.startTime}-${bussinessTime?.endTime}`: "Off Day"}</Typography>
                            {/* <Typography variant="subtitle1" > {(ownerBusiness?.businessLocations[0]?.openTime)}</Typography> */}
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default BusinessDetails;
