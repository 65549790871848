import React, { useEffect, useState } from 'react'
// material-ui
import { Button, Grid, InputAdornment, TextField } from '@mui/material'

// assets
import imageEmpty from 'assets/images/maintenance/Manicurist-amico.svg';
import { deleteCategory,  deletePackage,  updatePackage } from 'store/catalogue/slice';
// import TableCollapsible from 'pages/tables/TableCollapsible';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets

import { useCompanyData } from 'components/hooks/useCompanyData';
import { v4 as uuid } from 'uuid'
import { dispatch, useAppSelector as useSelector } from 'store';
import Loader from 'ui-component/Loader';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EmptyView from 'components/empty-view/EmptyView';
import CatalogueSkeleton from 'ui-component/cards/Skeleton/Catalogue';
import AlertServiceDelete from "pages/users/employees/AlertServiceUnassign";
import EditPackage from './EditPackage';
import { RowPackages } from './packagesRow';

const Packages = ({ user }) => {
    const [openDrawerpackage, setOpenDrawerPackage] = useState(false);
    const [editData, setEditData] = React.useState(null);
    const [deleteConfirm, setDeleteConfirm] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const serviceState = useSelector((state) => state.catalogue);
    const companyId = useCompanyData();
    const [packageList ,setPackageList] = React.useState(serviceState?.packagesList || []);
    const [search , setSearch] = React.useState('')
    let newRow = [];

    useEffect(()=>{
        // setPackageList(serviceState?.packagesList ?? []);
        setPackageList(serviceState?.packagesList)
    },[serviceState?.packagesList])
  

    const handleToggleAddPackageDialog = () => {
        setEditData(null);
        setOpenDrawerPackage(!openDrawerpackage);
        //setOpenDrawerPackage(true);
    };

    const handlePackageUpsert = async (body) => {
        setLoading(true);
        try {
            dispatch(updatePackage(body)).then((res)=>{
                setLoading(false);
                  handleToggleAddPackageDialog()
              });
           
            if (!serviceState.error) { setOpenDrawerPackage(false) };
        } catch (err) {
            console.log("err", err);
        }
    };

    const onHandleDelete = (data) => {
        setLoading(true);
        dispatch(deletePackage({ id: data?.id, companyId: companyId })).then(() => {
            setDeleteConfirm(null)
        }).then(() => {
            setLoading(false);
        });
    }

    const handleSearch = (e) => {
        if (e?.target.value == "") {
            setPackageList(serviceState.packagesList)
         }else{
              const filteredProducts = packageList?.filter((i)=> i?.title?.toLowerCase().includes(e?.target.value?.toLowerCase()) || i?.packageItems?.some((item)=>item?.title?.toLowerCase().includes(e?.target.value?.toLowerCase())
               ) );
              setPackageList(filteredProducts)
         }
         setSearch(e?.target.value)
    }
    
    if (serviceState.isFetching) return <CatalogueSkeleton />;
    
    return (
        <>
            {loading && <Loader />}
            <Grid item xs={12}>
                <MainCard
                    content={false}
                    title=""
                >
                    {/* table */}
                    <TableContainer>
                        <Table aria-label="collapsible table" size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                <TableCell sx={{ pl: 3 }} >
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                onClick={handleToggleAddPackageDialog}
                                                endIcon={<AddIcon />}
                                            >
                                                ADD
                                            </Button>
                                    </TableCell>

                                    <TableCell>Title &nbsp; (Total Packages)</TableCell>
                                    <TableCell >Status</TableCell>
                                    <TableCell align='right'>Price</TableCell>
                                    <TableCell align='right'>Discounted Price</TableCell>
                                    <TableCell sx={{ pr: 3 }} align="right" >
                                        <TextField
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SearchIcon fontSize="small"  cursor="pointer" />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={(e) => {
                                                handleSearch(e)
                                            }}
                                            placeholder="Search Package(s)"
                                            value={search}
                                            size="small"
                                            sx={{ width: { xs: 1, sm: 'auto' } }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {packageList?.length > 0 ?( 
                               packageList?.map((row) => (
                                    <RowPackages key={uuid()}
                                        row={row}
                                        onClickEditData={(data) => {
                                            setEditData(data);
                                            setOpenDrawerPackage(true);
                                        }}
                                        user={user}
                                        onDeleteData={(data) => { setDeleteConfirm(data) }} />
                                ))) : (
                                    <TableCell colSpan={6}>
                                        <EmptyView
                                            message1={"There is no Package"}
                                            message2={"When you have package, they will display here"}
                                            imgDark={imageEmpty}
                                        />
                                    </TableCell>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MainCard>
            </Grid>
            {/* <Dialog maxWidth="sm" fullWidth onClose={() => setOpenDrawerPackage(false)} open={openDrawerpackage} sx={{ '& .MuiDialog-paper': { p: 0 } }}> */}
                {openDrawerpackage && (
                    <EditPackage 
                        open={openDrawerpackage}
                        handleDrawerOpen={handleToggleAddPackageDialog}
                        onUpdatePackage={
                            handlePackageUpsert
                        }
                        editableData={editData}
                    />
                )}
            {/* </Dialog> */}

            {deleteConfirm && <AlertServiceDelete title="The selected item will be deleted."  open={deleteConfirm} handleClose={()=>setDeleteConfirm(null)} item={deleteConfirm} handleSubmit={onHandleDelete} />}
        </>
    )
}

export default Packages;
