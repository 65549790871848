// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/snackbar/slice';
import { ENDPOINTS } from 'network/ENDPOINT';
import { fetchUserData } from 'store/users/action';
import { fetchCountryList } from 'store/meta/action';
import { fetchMultiStoreList } from 'store/multi-store/action';
import { authenticate, setCountData, setShowOTPModal } from 'store/auth/slice';
import { getCategory, getPackage } from 'store/catalogue/slice';
import { getStaffs } from 'store/employees/slice';
import { getAllWeekdays, getBussinessSetUpData } from 'utils/general';
const daysOfWeek = getAllWeekdays();
// ----------------------------------------------------------------------

const initialState = {
    has_error: false,
    isFetching:false,
    isLoading:false,
    store:{},
    stores:[]
};

const slice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.has_error = action.payload;
        },
    

        getStoreScheduleSuccess(state, action){
            const workingDays = action.payload.filter(s=>s.isdayoff===false).map(d=>d.prefDay);
            state.store = {
                schedules:action.payload,
                off_days: daysOfWeek.filter(day=>!workingDays.includes(day.toLowerCase())).map(d=>d.toLowerCase())
            }
        },
        upsertStoreScheduleSuccess(state,action){
            const {id} = action.payload[0];
            const existingSchedules = state.store.schedules.filter(s=>s.id!=id);
            const off_days = state.store.off_days

            state.store = {
                schedules:[...existingSchedules, ...action.payload],
                off_days:off_days
            }
            
        },
        getStoreMetadataSuccess(state, action){
            // const businessHours = new Map();
            // const businessSetupData = action.payload;
            // state.businessSetupData = action.payload;
            // businessSetupData.forEach(bs => {
            //    const day = bs.prefDay;
            //    const timing = {
            //         start: bs.ENDPOINTS
            //    }
            //     if (!businessHours.has(day)) {

            //         day.set(s.employeeId, [shift]);
            //     }else{
            //             schedules.set(s.employeeId, [...schedules.get(s.employeeId),shift])
            //     }
            // })
            // state.businessSetupTime = getBussinessSetUpData(action.payload);
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setIsFetching(state, action) {
            state.isFetching = action.payload
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------



export function getStoreSchedule(companyId){
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true));
            const response = await axios.get(`${ENDPOINTS.GET_STORE_METADATA_API}?companyId=${companyId}`);
            await dispatch(slice.actions.getStoreScheduleSuccess(response.data.data))
            dispatch(slice.actions.setIsFetching(false));
            
            // dispatch(slice.actions.isReadyForOTPValidation (true));
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}


export function upsertStoreSchedule(body){
    return async () => {
        try {
            dispatch(slice.actions.setIsLoading(true));
            const response = await axios.post(ENDPOINTS.UPSERT_STORE_METADATA_API, body);
            //const response = await axios.get(`${ENDPOINTS.UPSERT_STORE_METADATA_API}?companyId=${companyId}`);
            await dispatch(slice.actions.upsertStoreScheduleSuccess(response.data.data))
            dispatch(slice.actions.setIsLoading(false));
            // dispatch(slice.actions.isReadyForOTPValidation (true));
            dispatch(openSnackbar ({
                open: true,
                message: 'The business hour has been configured for the selected Day.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false));
            dispatch(openSnackbar({
                open: true,
                message:  error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}


