import React from "react";
import PropTypes from "prop-types";

// material-ui
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// material-ui
// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
import { useAppSelector as useSelector } from "store";
// assets
import dayjs from "dayjs";
import { capitalize, userName } from "utils/general";
import { TableHead, useTheme, alpha } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import { ThemeMode } from "config";
import BorderRadius from "layout/Customization/BorderRadius";

export const AppointmentHistoryTable = ({ clientDetail }) => {
  const theme = useTheme();
  const { events } = useSelector((state) => state.calendar);
  const { employeeList } = useSelector((state) => state.employees);
  const { format } = useCountyPrice();
  let color;
  let chipcolor;
  const clientAppointment = events?.filter?.(
    (i) => i?.client === clientDetail?.id
  );

  return (
    <MainCard content={false} sx={{borderRadius:"0px !important"}}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750, border:"1px solid #e9e9e9"}}
          aria-labelledby="tableTitle"
          className="invoice-main-table"
        >
          <TableHead sx={{background:"#e3f2fd"}}>
            <TableRow>
              <TableCell>Service Name</TableCell>
              <TableCell>Appointment Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Staff Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {clientAppointment.map((row, index) => {
                const staff = employeeList?.find((i)=> i?.id === row?.staff);
                switch (row.status.toString()) {
                  case 'completed':
                      color = 'success.dark';
                      chipcolor = alpha(theme.palette.success.light, 0.6);
                      break;
                  case 'cancelled':
                      color = 'orange.dark';
                      chipcolor = alpha(theme.palette.error.light, 0.8);
                      break;
                  case 'pending':
                  default:
                      color = 'warning.dark';
                      chipcolor = 'warning.light';
                      break;
              }
              return (
                <TableRow
                  key={uuid()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell> {row.title}</TableCell>
                  <TableCell component="th" scope="row">
                    {dayjs(row.start).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {dayjs(row.start).format("HH:mm:ss")} -{" "}
                    {dayjs(row.end).format("HH:mm:ss")}
                  </TableCell>
                  <TableCell> {userName(staff?.firstName, staff?.lastName)}</TableCell>
                  <TableCell>
                    <Chip
                      label={capitalize(row.status)}
                      size="small"
                      sx={{
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : chipcolor,
                        color: color,
                        cursor: "pointer",
                      }}
                      // className={className}
                    />
                  </TableCell>
                  <TableCell align="right"> {format(row.price)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

AppointmentHistoryTable.propTypes = {
  clientDetail: PropTypes.object,
};

export default AppointmentHistoryTable;
