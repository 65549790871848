import { ShoppingCart } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useCountyPrice } from "components/hooks/useCountyPrice";
import React from "react";

const ProceedToCheckoutButton = ({ invoiceDetails, setDrawer, grossPrice }) => {
  const { format } = useCountyPrice();
  return (
    <div>
      {invoiceDetails && invoiceDetails?.length > 0 && (
        <div className="proceed-to-payment-div">
          <div>
            <h3>TOTAL</h3>
          </div>
          <button
            className="customButton"
            onClick={() => {
              setDrawer &&
                setDrawer({
                  open: true,
                  type: "CHECKOUT_DRAWER",
                  drawerWidth: "60%",
                  title: "CHECKOUT",
                  handleClose: () => {
                    setDrawer({ open: false });
                  },
                });
            }}
          >
            <Tooltip title={"PROCEED TO CHECKOUT"}>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <ShoppingCart />
                <h3>{format(grossPrice)}</h3>
              </div>
            </Tooltip>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProceedToCheckoutButton;
