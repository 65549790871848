import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { KeyboardArrowRight } from "@mui/icons-material";

export default function NewCommonDrawer({
  open,
  handleClose,
  children,
  drawerWidth,
  title = "",
  showIcon = "",
}) {
  return (
    <div>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        anchor={"right"}
        open={open}
        onClose={handleClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                borderBottom: "1px solid #e3e3e3",
                height: "8vh",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <div style={{ cursor: "pointer" }} onClick={handleClose}>
                  <KeyboardArrowRight />
                </div>
                {showIcon && showIcon === "cart" && <ShoppingCartIcon />}
                {title}
              </span>
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
            </div>
          </Grid>
          <Grid p={2} mt={1} item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
}
